import { gql } from '@apollo/client';

export const GET_LANGUAGE_LIST = gql`
  query GetLanguageList {
    getLanguageList {
      id
      iso_code
    }
  }
`;

export const GET_CURRENCY_LIST = gql`
  query GetCurrencyList {
    getCurrencyList {
      id
      currency_code
      currency_symbol
      exchange_rate_to_default
      format_pattern
    }
  }
`;

export const GET_COUNTRY_LIST = gql`
  query GetCountryList {
    getCountryList {
      id
      name
    }
  }
`;

export const GET_DEPARTMENT_LIST_BY_COUNTRY = gql`
  query GetDepartmentListByCountry($country_id: Int!) {
    getDepartmentListByCountry(country_id: $country_id) {
      id
      name
    }
  }
`;

export const GET_CITY_LIST_BY_DEPARTMENT = gql`
  query GetCityListByDepartment($department_id: Int!) {
    getCityListByDepartment(department_id: $department_id) {
      id
      name
    }
  }
`;

export const GET_DISTRICT_LIST_BY_CITY = gql`
  query GetDistrictListByCity($city_id: Int!) {
    getDistrictListByCity(city_id: $city_id) {
      id
      name
    }
  }
`;

export const GET_CATEGORY_LIST = gql`
  query GetCategoryList($lang_id: Int!) {
    getCategoryList(lang_id: $lang_id) {
      id
      parent_id
      name
      url_path
    }
  }
`;

export const GET_CATEGORY_DETAIL = gql`
  query GetCategoryDetail($category_id: Int!, $lang_id: Int!) {
    getCategoryDetail(category_id: $category_id, lang_id: $lang_id) {
      id
      name
      short_description
      description
      url_path
      meta_title
      meta_description
      meta_keywords
    }
  }
`;

export const GET_PRODUCT_LIST = gql`
  query GetProductList($page: Int!, $page_size: Int!, $category_id: Int!, $order_by: String, $order_direction: String, $lang_id: Int!) {
    getProductList(page: $page, page_size: $page_size, category_id: $category_id, order_by: $order_by, order_direction: $order_direction, lang_id: $lang_id) {
      length
      data {
        id
        name
        url_path
        base_price
        reduction_price
        reduction_percent
        url_image
      }
    }
  }
`;

export const GET_PRODUCT_DETAIL = gql`
  query GetProductDetail($product_id: Int!, $lang_id: Int!) {
    getProductDetail(product_id: $product_id, lang_id: $lang_id) {
      id
      name
      base_price
      reduction_price
      reduction_percent
      short_description
      description
      out_of_stock
      stock_quantity
      url_path
      meta_title
      meta_description
      meta_keywords
      features
      variations
    }
  }
`;

export const GET_PRODUCT_IMAGE_LIST = gql`
  query GetProductImageList($product_id: Int!) {
    getProductImageList(product_id: $product_id) {
      url
    }
  }
`;

export const GET_PRODUCT_ATTRIBUTES_OPTIONS = gql`
  query GetProductAttributesOptions($product_id: Int!, $lang_id: Int!) {
    getProductAttributesOptions(product_id: $product_id, lang_id: $lang_id) {
      attribute_id
      attribute_label
      attribute_name
      options_id
      options_name
    }
  }
`;

export const GET_DISCOUNT_DETAIL = gql`
  query GetDiscountDetail($sub: String, $carrier_id: Int, $payment_method_id: Int, $currency_id: Int!, $order_amount: Float!, $coupon: String!) {
    getDiscountDetail(sub: $sub, carrier_id: $carrier_id, payment_method_id: $payment_method_id, currency_id: $currency_id, order_amount: $order_amount, coupon: $coupon) {
      id
      type_discount
      value_discount
    }
  }
`;

export const GET_CARRIER_LIST = gql`
  query GetCarrierList($geography_codes: String!, $lang_id: Int!) {
    getCarrierList(geography_codes: $geography_codes, lang_id: $lang_id) {
      id
      code
      name
      rules
    }
  }
`;

export const GET_PAYMENT_METHOD_LIST = gql`
  query GetPaymentMethodList($lang_id: Int!) {
    getPaymentMethodList(lang_id: $lang_id) {
      id
      code
      name
    }
  }
`;

export const GET_ORDER_LIST = gql`
  query GetOrderList($customer_id: Int!) {
    getOrderList(customer_id: $customer_id) {
      id
      cart_id
      payment_method_id
      carrier_id
    }
  }
`;

export const GET_CART_PRODUCT_DETAILS = gql`
  query GetCartProductDetails($product_ids: String!, $lang_id: Int!) {
    getCartProductDetails(product_ids: $product_ids, lang_id: $lang_id) {
      id
      name
      base_price
      reduction_price
      reduction_percent
      stock_quantity
      weight
      full_url_path
    }
  }
`;