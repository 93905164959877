import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useSettings } from '../context/SettingsContext';
import { useCategory } from '../context/CategoriesContext';
import { GET_CATEGORY_LIST } from '../graphql';

const useCategories = () => {
    const { language } = useSettings();
    const { categories, setCategories } = useCategory();
    const { loading, error, data } = useQuery(GET_CATEGORY_LIST, {
        variables: {
            lang_id: language.id
        },
        fetchPolicy: 'cache-first',
        skip: categories.length > 0,
    });
    
    useEffect(() => {
        if (data && data.getCategoryList) {
            setCategories(data.getCategoryList);
        }
    }, [data]);

    useEffect(() => {
        setCategories([]);
    }, [language]);

    return {
        loading: categories.length === 0 ? loading : false,
        error,
        data: categories.length > 0 ? categories : data?.getCategoryList || []
    };
};

export default useCategories;
