import React, { createContext, useState, useEffect, useContext } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || { data: [] });
    const [quantities, setQuantities] = useState(cart && cart.data ? cart.data.map(item => item.quantity) : []);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
        if (cart && cart.data) {
            setQuantities(cart.data.map(item => item.quantity));
        } else {
            setQuantities([]);
        }
    }, [cart]);

    useEffect(() => {
        const syncCartAcrossTabs = (event) => {
            if (event.key === 'cart') {
                const newCart = JSON.parse(event.newValue);
                if (newCart && JSON.stringify(newCart) !== JSON.stringify(cart)) {
                    setCart(newCart);
                }
            }
        };
        window.addEventListener('storage', syncCartAcrossTabs);

        return () => {
            window.removeEventListener('storage', syncCartAcrossTabs);
        };
    }, [cart]);

    return (
        <CartContext.Provider value={{ cart, setCart, quantities, setQuantities }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);