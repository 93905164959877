import { ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import awsConfig from '../aws-config';

export const createErrorLink = ({ authData, setAuthData }) =>
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ errorType }) => {
                if (['UnauthorizedException', 'Unauthorized'].includes(errorType)) {
                    setAuthData({
                        ...authData,
                        is_authenticated: false,
                        session_token: null,
                        sub: null,
                        email: null
                    });
                    window.location.href = '/login';
                }
            });
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
        }
    });

export const createAuthLink = (sessionToken) => {
    return setContext((_, { headers }) => ({
        headers: {
            ...headers,
            Authorization: sessionToken ? `Bearer ${sessionToken}` : '',
        },
    }));
};

export const httpLinkWithApiKey = new HttpLink({
    uri: awsConfig.API.GraphQL.endpoint,
    headers: {
        'x-api-key': awsConfig.API.GraphQL.apiKey,
    },
});

export const httpLinkWithoutApiKey = new HttpLink({
    uri: awsConfig.API.GraphQL.endpoint
});

export const createLink = (sessionToken, authHandlers) => {
    const authLink = createAuthLink(sessionToken);
    const errorLink = createErrorLink(authHandlers);
    return ApolloLink.from([errorLink, authLink, httpLinkWithoutApiKey]);
};