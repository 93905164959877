import React from 'react';
import CategoryBreadcrumb from "../components/Category/CategoryBreadcrumb";
import ProductCard from "../components/Product/ProductCard";

const Products = () => {
    return (
        <div>
            <CategoryBreadcrumb />
            <ProductCard />
        </div>
    );
};
  
export default Products;