import React from 'react';
import Menu from "./Menu";
import Settings from "./Settings";
import MonitorGuest from "../Shared/MonitorGuest";

const Header = () => {
    return (
        <header>
            <MonitorGuest />
            <Settings />
            <Menu />
        </header>
    );
};

export default Header;