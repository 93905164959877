import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import Categories from './pages/Categories';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import NotFound from './pages/NotFound';
import AboutUs from './pages/AboutUs';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiesPolicy from './pages/CookiesPolicy';
import ReturnsPolicy from './pages/ReturnsPolicy';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Cart from './components/Cart/Cart';
import ScrollToTop from './components/Shared/ScrollToTop';

function App() {
    return (
        <AppProvider>
            <Router>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tu Tienda Online - Productos de Calidad</title>
                    <meta name="description" content="Compra productos de alta calidad, ofertas especiales y las últimas novedades en nuestra tienda online." />
                    <meta name="keywords" content="tienda online, productos en oferta, productos nuevos, mejores productos" />
                    <meta name="author" content="Tu Tienda Online" />
                    <html lang="es" />
                </Helmet>
                <Header />
                <ScrollToTop />
                <main>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/home" element={<Categories />} />
                        <Route path="/:category_param" element={<Products />} />
                        <Route path="/:category_param/:product_param" element={<ProductDetails />} />
                        <Route exact path="/cart" element={<Cart />} />
                        <Route exact path="/carrito" element={<Cart />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/profile" element={<Profile />} />
                        <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route exact path="/terminos-y-condiciones" element={<TermsAndConditions />} />
                        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route exact path="/politica-de-privacidad" element={<PrivacyPolicy />} />
                        <Route exact path="/cookies-policy" element={<CookiesPolicy />} />
                        <Route exact path="/politica-de-cookies" element={<CookiesPolicy />} />
                        <Route exact path="/returns-policy" element={<ReturnsPolicy />} />
                        <Route exact path="/politica-de-devoluciones" element={<ReturnsPolicy />} />
                        <Route exact path="/about-us" element={<AboutUs />} />
                        <Route exact path="/acerca-de-nosotros" element={<AboutUs />} />
                        <Route exact path="/404" element={<NotFound />} />
                    </Routes>
                </main>
                <Footer />
            </Router>
        </AppProvider>
    );
}

export default App;
