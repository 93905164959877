import React from 'react';
import useTranslation from '../hooks/useTranslation';
import { Layout, Typography, Menu } from 'antd';

const { Sider } = Layout;
const { Title, Paragraph } = Typography;

const ReturnsPolicy = () => {
    const translations = useTranslation('returns_policy');

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                width={300}
                breakpoint='md'
                collapsedWidth="0"
                style={{
                    background: '#fff',
                    position: 'sticky',
                    top: 0,
                    paddingTop: 30,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Menu mode="inline" defaultSelectedKeys={['introduction']} style={{ height: '100%', borderRight: 0 }}>
                    <Menu.Item key="introduction">
                        <a href="#introduction">{translations.rp_introduction}</a>
                    </Menu.Item>
                    <Menu.Item key="return-conditions">
                        <a href="#return-conditions">{translations.rp_return_conditions}</a>
                    </Menu.Item>
                    <Menu.Item key="return-period">
                        <a href="#return-period">{translations.rp_return_period}</a>
                    </Menu.Item>
                    <Menu.Item key="non-returnable-items">
                        <a href="#non-returnable-items">{translations.rp_non_returnable_items}</a>
                    </Menu.Item>
                    <Menu.Item key="return-process">
                        <a href="#return-process">{translations.rp_return_process}</a>
                    </Menu.Item>
                    <Menu.Item key="return-shipping-costs">
                        <a href="#return-shipping-costs">{translations.rp_return_shipping_costs}</a>
                    </Menu.Item>
                    <Menu.Item key="refunds">
                        <a href="#refunds">{translations.rp_refunds}</a>
                    </Menu.Item>
                    <Menu.Item key="exchanges">
                        <a href="#exchanges">{translations.rp_exchanges}</a>
                    </Menu.Item>
                    <Menu.Item key="damaged-items">
                        <a href="#damaged-items">{translations.rp_damaged_items}</a>
                    </Menu.Item>
                    <Menu.Item key="inspection-and-condition">
                        <a href="#inspection-and-condition">{translations.rp_inspection_and_condition}</a>
                    </Menu.Item>
                    <Menu.Item key="updates-to-returns-policy">
                        <a href="#updates-to-returns-policy">{translations.rp_updates_to_returns_policy}</a>
                    </Menu.Item>
                    <Menu.Item key="contact-information">
                        <a href="#contact-information">{translations.rp_contact_information}</a>
                    </Menu.Item>
                </Menu>
            </Sider>

            <Layout style={{ margin: 40 }}>
                <div style={{ minHeight: '100vh' }}>
                    <Typography>
                        <Title level={3} id="introduction">
                            1. {translations.rp_introduction}
                        </Title>
                        <Paragraph>{translations.rp_introduction_content}</Paragraph>

                        <Title level={3} id="return-conditions">
                            2. {translations.rp_return_conditions}
                        </Title>
                        <Paragraph>{translations.rp_return_conditions_content}</Paragraph>

                        <Title level={3} id="return-period">
                            3. {translations.rp_return_period}
                        </Title>
                        <Paragraph>{translations.rp_return_period_content}</Paragraph>

                        <Title level={3} id="non-returnable-items">
                            4. {translations.rp_non_returnable_items}
                        </Title>
                        <Paragraph>{translations.rp_non_returnable_items_content}</Paragraph>

                        <Title level={3} id="return-process">
                            5. {translations.rp_return_process}
                        </Title>
                        <Paragraph>{translations.rp_return_process_content}</Paragraph>

                        <Title level={3} id="return-shipping-costs">
                            6. {translations.rp_return_shipping_costs}
                        </Title>
                        <Paragraph>{translations.rp_return_shipping_costs_content}</Paragraph>

                        <Title level={3} id="refunds">
                            7. {translations.rp_refunds}
                        </Title>
                        <Paragraph>{translations.rp_refunds_content}</Paragraph>

                        <Title level={3} id="exchanges">
                            8. {translations.rp_exchanges}
                        </Title>
                        <Paragraph>{translations.rp_exchanges_content}</Paragraph>

                        <Title level={3} id="damaged-items">
                            9. {translations.rp_damaged_items}
                        </Title>
                        <Paragraph>{translations.rp_damaged_items_content}</Paragraph>

                        <Title level={3} id="inspection-and-condition">
                            10. {translations.rp_inspection_and_condition}
                        </Title>
                        <Paragraph>{translations.rp_inspection_and_condition_content}</Paragraph>

                        <Title level={3} id="updates-to-returns-policy">
                            11. {translations.rp_updates_to_returns_policy}
                        </Title>
                        <Paragraph>{translations.rp_updates_to_returns_policy_content}</Paragraph>

                        <Title level={3} id="contact-information">
                            12. {translations.rp_contact_information}
                        </Title>
                        <Paragraph>{translations.rp_contact_information_content}</Paragraph>
                    </Typography>
                </div>
            </Layout>
        </Layout>
    );
};

export default ReturnsPolicy;