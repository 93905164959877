import React from 'react';
import MainBanner from "../components/Home/MainBanner";
import CategoryList from "../components/Category/CategoryList";

const Categories = () => {
    return (
        <div>
            <MainBanner />
            <CategoryList />
        </div>
    );
};
  
export default Categories;