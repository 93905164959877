import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import { useSettings } from '../../context/SettingsContext';
import { GET_PRODUCT_DETAIL, GET_PRODUCT_ATTRIBUTES_OPTIONS, GET_PRODUCT_IMAGE_LIST } from '../../graphql';
import useFormatNumber from '../../hooks/useFormatNumber';
import useTranslation from '../../hooks/useTranslation';
import AddToCartButton from './AddToCartButton';
import { Card, Typography, Table, Alert, Divider, Skeleton, Image, Row, Col, Select, Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import './ProductCard.css';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

const ProductCard = () => {
    const navigate = useNavigate();
    const { product_param } = useParams();
    const { language, currency } = useSettings();
    const translations = useTranslation('product');

    let product_id = null;
    if (product_param && product_param.lastIndexOf('-') !== -1) {
        product_id = parseInt(product_param.substring(product_param.lastIndexOf('-') + 1));
    }

    const { loading, error, data } = useQuery(GET_PRODUCT_DETAIL, {
        variables: {
            product_id: product_id,
            lang_id: language.id
        },
        fetchPolicy: 'cache-first'
    });

    const formatNumber = useFormatNumber();
    const product = data?.getProductDetail;

    const [finalPrice, setFinalPrice] = useState(product?.base_price || 0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [visibleImagesStart, setVisibleImagesStart] = useState(0);

    const { data: attributesData } = useQuery(GET_PRODUCT_ATTRIBUTES_OPTIONS, {
        variables: {
            product_id: product_id,
            lang_id: language.id
        },
        skip: !product?.variations,
    });

    const { data: imagesData } = useQuery(GET_PRODUCT_IMAGE_LIST, {
        variables: {
            product_id: product_id
        },
        skip: !product_id,
    });

    useEffect(() => {
        if (!loading && !product?.id) {
            navigate('/404', { replace: true });
        }
    }, [loading, product]);

    useEffect(() => {
        if (product) {
            setFinalPrice(product.base_price);
        }
    }, [product]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : imageUrls.length - 1));
            } else if (event.key === 'ArrowRight') {
                setCurrentImageIndex((prevIndex) => (prevIndex < imageUrls.length - 1 ? prevIndex + 1 : 0));
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [imagesData]);

    const handleAttributeChange = (value) => {
        const selectedOption = attributesOptions.find(attr => attr.key === value);
        if (selectedOption) {
            setFinalPrice(product.base_price + selectedOption.value);
        }
    };

    if (loading || error) return (
        <Card bordered={false} className="product-details">
            <Skeleton active paragraph={{ rows: 4 }} />
        </Card>
    );

    const columns = [
        {
            title: translations.product_card_feature,
            dataIndex: 'feature',
            key: 'feature',
        },
        {
            title: translations.product_card_value,
            dataIndex: 'value',
            key: 'value',
        }
    ];

    const featuresData = product.features ? Object.entries(JSON.parse(product.features)).map(([key, value], index) => ({
        key: index,
        feature: key,
        value: value,
    })) : [];

    const attributesOptions = attributesData?.getProductAttributesOptions?.map(attr => ({
        attributeId: attr.attribute_id,
        attributeName: attr.attribute_name,
        attributeLabel: attr.attribute_label,
        options: JSON.parse(attr.options_name).map((optionName, i) => ({
            id: JSON.parse(attr.options_id)[i],
            name: optionName
        })),
    })) || [];

    const baseImageUrl = 'http://ekadabra.com.s3-website-us-east-1.amazonaws.com/images/products/' + product_id + '/';
    const imageUrls = imagesData?.getProductImageList?.map((imageName) => baseImageUrl + imageName.url) || [];
    console.log('imageUrls', imageUrls)

    const visibleImages = imageUrls.slice(visibleImagesStart, visibleImagesStart + 3);

    const handleNextImages = () => {
        if (visibleImagesStart + 3 < imageUrls.length) {
            setVisibleImagesStart(visibleImagesStart + 1);
        }
    };

    const handlePreviousImages = () => {
        if (visibleImagesStart > 0) {
            setVisibleImagesStart(visibleImagesStart - 1);
        }
    };

    return (
        <Card bordered={false} className="product-details">
            <Helmet>
                <title>{product.meta_title}</title>
                <meta name="description" content={product.meta_description} />
                <meta name="keywords" content={product.meta_keywords} />
            </Helmet>

            <Row gutter={48}>
                <Col xs={24} sm={9}>
                    <div className="image-gallery-wrapper" style={{ position: 'relative' }}>
                        {imageUrls.length > 0 ? (
                            <Image
                                width="100%"
                                src={imageUrls[currentImageIndex]}
                                alt={product.name}
                            />
                        ) : (
                            // <Skeleton.Image style={{ width: '100%', height: 300 }} />
                            <div className="skeleton-image">
                                <Skeleton.Image style={{ width: 300, height: 300 }} active />
                            </div>
                        )}
                    </div>
                    {imageUrls.length > 0 &&
                        <Row gutter={[8, 8]} style={{ marginTop: '10px', position: 'relative' }}>
                            <Button
                                icon={<LeftOutlined />}
                                onClick={handlePreviousImages}
                                style={{ position: 'absolute', top: 'calc(50% - 14px)', left: '-14px', zIndex: 1 }}
                                disabled={visibleImagesStart === 0}
                            />
                            {visibleImages.map((img, index) => (
                                <Col key={index} span={8}>
                                    <Image
                                        width="100%"
                                        preview={false}
                                        src={img}
                                        alt={`${product.name} - ${index + 1}`}
                                        onClick={() => setCurrentImageIndex(visibleImagesStart + index)}
                                        style={{ cursor: 'pointer', border: currentImageIndex === (visibleImagesStart + index) ? '2px solid #1890ff' : 'none' }}
                                    />
                                </Col>
                            ))}
                            <Button
                                icon={<RightOutlined />}
                                onClick={handleNextImages}
                                style={{ position: 'absolute', top: 'calc(50% - 14px)', right: '-14px', zIndex: 1 }}
                                disabled={visibleImagesStart + 3 >= imageUrls.length}
                            />
                        </Row>
                    }
                </Col>

                <Col xs={24} sm={15}>
                    <Title level={1} style={{ marginTop: 10, marginBottom: 10 }}>{product.name}</Title>
                    <Divider />
                    <Paragraph strong>{product.short_description}</Paragraph>
                    <Paragraph>{product.description}</Paragraph>
                    <Divider />
                    <Paragraph style={{ textAlign: 'center' }}>
                        {product.reduction_price ? (
                            <>
                                <Text type="danger" strong style={{ fontSize: 30 }}>
                                    {formatNumber(finalPrice * currency.exchange_rate_to_default, currency.format_pattern)}
                                </Text>{' '}
                                <Text delete style={{ fontSize: 30 }}>
                                    {formatNumber(product.base_price * currency.exchange_rate_to_default, currency.format_pattern)}
                                </Text>{' '}
                                <Text type="warning" style={{ fontSize: 30 }}>
                                    {'-'}{product.reduction_percent}%
                                </Text>
                            </>
                        ) : (
                            <Text mark strong style={{ fontSize: 30 }}>
                                {formatNumber(finalPrice * currency.exchange_rate_to_default, currency.format_pattern)}
                            </Text>
                        )}
                    </Paragraph>

                    {!product.out_of_stock && <AddToCartButton product={product} />}

                    {product.out_of_stock ? (
                        <Alert message={translations.product_card_out_of_stock} type="error" icon={<CloseCircleOutlined />} />
                    ) : (
                        <Text type="success">
                            <CheckCircleOutlined /> {translations.product_card_available_stock}: {product.stock_quantity}
                        </Text>
                    )}

                    {attributesOptions.length > 0 && (
                        <div style={{ marginTop: 16 }}>
                            {attributesOptions.map((attr) => (
                                <Row key={attr.attributeId} gutter={16} style={{ marginBottom: 16, alignItems: 'center' }}>
                                    <Col span={6}>
                                        <label>{attr.attributeName}:</label>
                                    </Col>
                                    <Col span={18}>
                                        <Select
                                            placeholder={attr.attributeLabel}
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleAttributeChange(attr.attributeName, value)}
                                            allowClear
                                        >
                                            {attr.options.map((opt) => (
                                                <Option key={opt.id} value={opt.name}>
                                                    {opt.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    )}
                </Col>
            </Row>

            {featuresData.length > 0 && (
                <>
                    <Divider />
                    <Table
                        dataSource={featuresData}
                        columns={columns}
                        pagination={false}

                    />
                    <Divider />
                </>
            )}
        </Card>
    );
};

export default ProductCard;