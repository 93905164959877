import { useCallback } from 'react';

const useFormatNumber = () => {
    const formatNumber = useCallback((number, format) => {
        const formattedNumber = number.toFixed(2);
        const result = format.replace('###.##', formattedNumber);
        return result;
    }, []);

    return formatNumber;
};

export default useFormatNumber;
