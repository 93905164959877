import React from 'react';
import useTranslation from '../hooks/useTranslation';
import { Layout, Typography, Menu } from 'antd';

const { Sider } = Layout;
const { Title, Paragraph } = Typography;

const CookiesPolicy = () => {
    const translations = useTranslation('cookies_policy');

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                width={300}
                breakpoint='md'
                collapsedWidth="0"
                style={{
                    background: '#fff',
                    position: 'sticky',
                    top: 0,
                    paddingTop: 30,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Menu mode="inline" defaultSelectedKeys={['introduction']} style={{ height: '100%', borderRight: 0 }}>
                    <Menu.Item key="introduction">
                        <a href="#introduction">{translations.cop_introduction}</a>
                    </Menu.Item>
                    <Menu.Item key="what-are-cookies">
                        <a href="#what-are-cookies">{translations.cop_what_are_cookies}</a>
                    </Menu.Item>
                    <Menu.Item key="types-of-cookies-used">
                        <a href="#types-of-cookies-used">{translations.cop_types_of_cookies_used}</a>
                    </Menu.Item>
                    <Menu.Item key="why-we-use-cookies">
                        <a href="#why-we-use-cookies">{translations.cop_why_we_use_cookies}</a>
                    </Menu.Item>
                    <Menu.Item key="third-party-cookies">
                        <a href="#third-party-cookies">{translations.cop_third_party_cookies}</a>
                    </Menu.Item>
                    <Menu.Item key="cookie-management">
                        <a href="#cookie-management">{translations.cop_cookie_management}</a>
                    </Menu.Item>
                    <Menu.Item key="consent-to-use-cookies">
                        <a href="#consent-to-use-cookies">{translations.cop_consent_to_use_cookies}</a>
                    </Menu.Item>
                    <Menu.Item key="updates-to-cookie-policy">
                        <a href="#updates-to-cookie-policy">{translations.cop_updates_to_cookie_policy}</a>
                    </Menu.Item>
                    <Menu.Item key="contact-information">
                        <a href="#contact-information">{translations.cop_contact_information}</a>
                    </Menu.Item>
                </Menu>
            </Sider>

            <Layout style={{ margin: 40 }}>
                <div style={{ minHeight: '100vh' }}>
                    <Typography>
                        <Title level={3} id="introduction">
                            1. {translations.cop_introduction}
                        </Title>
                        <Paragraph>{translations.cop_introduction_content}</Paragraph>

                        <Title level={3} id="what-are-cookies">
                            2. {translations.cop_what_are_cookies}
                        </Title>
                        <Paragraph>{translations.cop_what_are_cookies_content}</Paragraph>

                        <Title level={3} id="types-of-cookies-used">
                            3. {translations.cop_types_of_cookies_used}
                        </Title>
                        <Paragraph>{translations.cop_types_of_cookies_used_content}</Paragraph>
                        <ul>
                            {translations.cop_types_of_cookies_used_list ? translations.cop_types_of_cookies_used_list.map((item, index) => (
                                <li key={index}>{item}</li>
                            )) : ''}
                        </ul>

                        <Title level={3} id="why-we-use-cookies">
                            4. {translations.cop_why_we_use_cookies}
                        </Title>
                        <Paragraph>{translations.cop_why_we_use_cookies_content}</Paragraph>

                        <Title level={3} id="third-party-cookies">
                            5. {translations.cop_third_party_cookies}
                        </Title>
                        <Paragraph>{translations.cop_third_party_cookies_content}</Paragraph>

                        <Title level={3} id="cookie-management">
                            6. {translations.cop_cookie_management}
                        </Title>
                        <Paragraph>{translations.cop_cookie_management_content}</Paragraph>

                        <Title level={3} id="consent-to-use-cookies">
                            7. {translations.cop_consent_to_use_cookies}
                        </Title>
                        <Paragraph>{translations.cop_consent_to_use_cookies_content}</Paragraph>

                        <Title level={3} id="updates-to-cookie-policy">
                            8. {translations.cop_updates_to_cookie_policy}
                        </Title>
                        <Paragraph>{translations.cop_updates_to_cookie_policy_content}</Paragraph>

                        <Title level={3} id="contact-information">
                            9. {translations.cop_contact_information}
                        </Title>
                        <Paragraph>{translations.cop_contact_information_content}</Paragraph>
                    </Typography>
                </div>
            </Layout>
        </Layout>
    );
};

export default CookiesPolicy;