import { gql } from '@apollo/client';

export const INSERT_GUEST = gql`
  mutation InsertGuest($input: GuestInput!) {
    insertGuest(input: $input) {
      token_id
    }
  }
`;

export const INSERT_CART = gql`
  mutation InsertCart($input: CartInput!) {
    insertCart(input: $input) {
      token_id
    }
  }
`;

export const INSERT_CART_PRODUCT = gql`
  mutation InsertCartProduct($input: CartProductInput!) {
    insertCartProduct(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_CART_PRODUCT = gql`
  mutation UpdateCartProduct($input: CartProductInput!) {
    updateCartProduct(input: $input) {
      success
      message
    }
  }
`;

export const DELETE_CART_PRODUCT = gql`
  mutation DeleteCartProduct($input: CartProductInput!) {
    deleteCartProduct(input: $input) {
      success
      message
    }
  }
`;

export const INSERT_ORDER = gql`
  mutation InsertOrder($input: OrderInput!) {
    insertOrder(input: $input) {
      success
      message
    }
  }
`;