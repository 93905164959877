import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import { useSettings } from '../../context/SettingsContext';
import { GET_CATEGORY_DETAIL } from '../../graphql';
import { Skeleton, Card, Typography } from 'antd';
import './CategoryCard.css';

const { Title, Paragraph } = Typography;

const CategoryCard = () => {
    const navigate = useNavigate();
    const { category_param } = useParams();
    const { language } = useSettings();

    let category_id = null;
    let category_url_path = null;
    if (category_param && category_param.lastIndexOf('-') !== -1) {
        category_id = parseInt(category_param.substring(category_param.lastIndexOf('-') + 1));
        category_url_path = category_param.substring(0, category_param.lastIndexOf('-'));
    }
    const { loading, error, data } = useQuery(GET_CATEGORY_DETAIL, {
        variables: {
            category_id: category_id,
            lang_id: language.id
        },
        fetchPolicy: 'cache-first'
    });

    const category = data?.getCategoryDetail;

    useEffect(() => {
        if (!loading && (!category?.id || category?.url_path !== category_url_path)) {
            navigate('/404', { replace: true });
        }
    }, [loading, category, category_url_path]);

    if (loading || error) {
        return (
            <div className="category-container">
                <Skeleton active paragraph={{ rows: 6 }} />
            </div>
        );
    }

    return (
        <div className="category-container">
            <Helmet>
                <title>{category.meta_title}</title>
                <meta name="description" content={category.meta_description} />
                <meta name="keywords" content={category.meta_keywords} />
            </Helmet>
            <Card bordered={true}>
                <Title level={1}>{category.name}</Title>
                <Paragraph strong>{category.short_description}</Paragraph>
                <Paragraph>
                    <p dangerouslySetInnerHTML={{ __html: category.description }}></p>
                </Paragraph>
            </Card>
        </div>
    );
};

export default CategoryCard;