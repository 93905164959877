const awsConfig = {
    "aws_project_region": "us-east-1",
    "API": {
        "GraphQL": {
            "endpoint": "https://pwn5sln4dndj3glqfm3njegloe.appsync-api.us-east-1.amazonaws.com/graphql",
            "region": "us-east-1",
            "defaultAuthMode": "apiKey",
            "apiKey": "da2-g5ekdidnvvh5faceqxebq5jcbm"
        }
    },
    "Auth": {
        "region": "us-east-1",
        "userPoolId": "us-east-1_MWgB4v8ZU",
        "userPoolWebClientId": "74bq6mojgk2n91vrq28bdl743n",
        "domain": "ekadabra.auth.us-east-1.amazoncognito.com"
    }
};

export default awsConfig;