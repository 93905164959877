import React from 'react';
import { Card, Typography, Row, Col, Button, Tag, Divider } from 'antd';
import { TeamOutlined, GlobalOutlined, TrophyOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

const AboutUsContent = () => {
  return (
    <Row justify="center" style={{ padding: '50px', background: 'linear-gradient(45deg, #e3f2fd, #90caf9)' }}>
      <Col xs={24} md={18} lg={12}>
        <Card
          hoverable
          bordered={false}
          style={{
            borderRadius: '15px',
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
            overflow: 'hidden',
          }}
          cover={
            <img
              alt="team"
              src="https://example.com/team-photo.jpg"
              style={{ borderRadius: '15px 15px 0 0', objectFit: 'cover', height: '250px', transition: 'transform 0.5s' }}
              onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
              onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
            />
          }
        >
          <div style={{ textAlign: 'center' }}>
            <Title level={1} style={{ fontFamily: 'Montserrat', color: '#003366' }}>About Us</Title>
            <Tag color="blue" style={{ marginBottom: '20px' }}>Committed to Excellence</Tag>
            <Paragraph style={{ fontSize: '16px', lineHeight: '1.8', color: '#666' }}>
              <Text strong>We are a global leader</Text> in delivering high-quality services, with a strong commitment to excellence and innovation. 
              Our dedicated team is always striving to push the boundaries, ensuring we remain at the forefront of the industry.
            </Paragraph>
            <Paragraph style={{ fontSize: '16px', lineHeight: '1.8', color: '#666' }}>
              Our mission is to deliver exceptional experiences to our clients, leveraging <Text italic>technology and creativity</Text> to solve the most challenging problems.
            </Paragraph>
            
            <Divider>Our Values</Divider>

            <Row justify="space-around" gutter={[16, 16]}>
              <Col span={8}>
                <TeamOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
                <Paragraph>Teamwork</Paragraph>
              </Col>
              <Col span={8}>
                <GlobalOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
                <Paragraph>Global Impact</Paragraph>
              </Col>
              <Col span={8}>
                <TrophyOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
                <Paragraph>Excellence</Paragraph>
              </Col>
            </Row>

            <Button 
              type="primary" 
              size="large" 
              style={{ marginTop: '30px', borderRadius: '30px', padding: '0 40px', backgroundColor: '#003366', borderColor: '#003366' }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#005bb5'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#003366'}
            >
              Contact Us
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default AboutUsContent;