import React, { createContext, useState, useEffect, useContext } from 'react';

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [language, setLanguage] = useState(JSON.parse(localStorage.getItem('language') || '{"id":1,"iso_code":"ES"}'));
  const [currency, setCurrency] = useState(JSON.parse(localStorage.getItem('currency') || '{"id":1,"currency_code":"PEN","currency_symbol":"S/","exchange_rate_to_default":1,"format_pattern":"S/. ###.##"}'));

  useEffect(() => {
    localStorage.setItem('language', JSON.stringify(language));
  }, [language]);

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(currency));
  }, [currency]);

  return (
    <SettingsContext.Provider value={{
      language, setLanguage,
      currency, setCurrency,
    }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);