import React from 'react';
import CategoryBreadcrumb from "../components/Category/CategoryBreadcrumb";
import CategoryCard from "../components/Category/CategoryCard";
import CategoryList from "../components/Category/CategoryList";
import ProductList from "../components/Product/ProductList";

const Products = () => {
    return (
        <div>
            <CategoryBreadcrumb />
            <CategoryCard />
            <CategoryList />
            <ProductList />
        </div>
    );
};
  
export default Products;