import React from 'react';
import { Button, Card, Image, Typography } from 'antd';
import './MainBanner.css';
import mainBannerImage from '../../resources/main-banner-4.webp';

const { Title, Paragraph } = Typography;

const MainBanner = () => {
    return (
        <div className="main-banner">
            <Card bordered={false} className="main-banner-card">
                <Image
                    src={mainBannerImage}
                    preview={false}
                    className="main-banner-image"
                    alt="Main Banner"
                />
                <div className="main-banner-content">
                    <Title level={1}>Discover Our Exclusive Collection</Title>
                    <Paragraph>Unleash style and comfort with our top picks for the season.</Paragraph>
                    <Button type="primary" size="large" href="#shop-now">
                        Shop Now
                    </Button>
                </div>
            </Card>
        </div>
    );
};

export default MainBanner;