import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import useCategories from '../../hooks/useCategories';
import './CategoryBreadcrumb.css';

const CategoryBreadcrumb = () => {
    const theme_breadcrumb_color = process.env.REACT_APP_THEME_BREADCRUMB_COLOR;

    const { category_param } = useParams();
    const { loading, error, data: categories } = useCategories();

    if (loading || error) return <nav className="breadcrumb-modern"></nav>;

    const findCategoryPath = (category_id, categories) => {
        let path = [];
        let category = categories.find(cat => cat.id === category_id);
        while (category) {
            const currentCategory = category;
            path.unshift(currentCategory);
            category = categories.find(c => c.id === currentCategory.parent_id);
        }
        return path;
    };

    let category_id = null;
    if (category_param && category_param.lastIndexOf('-') !== -1) {
        category_id = parseInt(category_param.substring(category_param.lastIndexOf('-') + 1));
    }
    const breadcrumbPath = findCategoryPath(category_id, categories);

    return (
        <nav className="breadcrumb-modern" style={{ '--theme-breadcrumb-color': theme_breadcrumb_color }}>
            <ol className="breadcrumb-list-modern">
                <li className="breadcrumb-item-modern">
                    <Link to={`/home`} className="breadcrumb-link-modern">
                        <FontAwesomeIcon icon={faHome} />
                    </Link>
                    {breadcrumbPath.length > 0 && (
                        <span className="breadcrumb-separator-modern">{'›'}</span>
                    )}
                </li>
                {breadcrumbPath.map((category, index) => (
                    <li key={category.id} className="breadcrumb-item-modern">
                        <Link to={`/${category.url_path}-${category.id}`} className="breadcrumb-link-modern">
                            {category.name}
                        </Link>
                        {index < breadcrumbPath.length - 1 && (
                            <span className="breadcrumb-separator-modern">{'›'}</span>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default CategoryBreadcrumb;