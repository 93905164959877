import React from 'react';
import { Input, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faInstagram,
    faXTwitter,
    faLinkedin,
    faYoutube,
    faCcVisa,
    faCcMastercard,
    faCcPaypal
} from '@fortawesome/free-brands-svg-icons';
import { ReactComponent as FaCcYape } from '../../resources/yape.svg';
import { ReactComponent as FaCcPlin } from '../../resources/plin.svg';
import useTranslation from '../../hooks/useTranslation';
import './Footer.css';

const Footer = () => {
    const theme_background_clear_color = process.env.REACT_APP_THEME_BACKGROUND_CLEAR_COLOR;
    const translations = useTranslation();

    return (
        <footer className="footer-aws" style={{ '--theme-background-clear-color': theme_background_clear_color }}>
            <section className="footer-content">
                <div className="footer-subscribe">
                    <h4 className="footer-heading">{translations.footer_subscribe_newsletter}</h4>
                    <p className="footer-description">{translations.footer_newsletter_description}</p>
                    <form className="footer-subscribe-form">
                        <Input
                            type="email"
                            className="footer-input"
                            placeholder={translations.footer_email_placeholder}
                            required
                            style={{ width: '300px', marginBottom: '10px' }}
                        />
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="footer-button"
                            style={{
                                background: 'linear-gradient(90deg, rgba(138, 51, 177, 1) 0%, rgba(0, 162, 255, 1) 100%)',
                                border: 'none',
                            }}
                        >
                            {translations.footer_subscribe_button}
                        </Button>
                    </form>
                </div>
                <div className="footer-links">
                    <div className="footer-section">
                        <h4 className="footer-heading">{translations.about_us}</h4>
                        <nav>
                            <ul className="footer-nav-list">
                                <li><a href="/quienes-somos" className="footer-link">{translations.about_us_who}</a></li>
                                <li><a href="/nuestra-mision" className="footer-link">{translations.about_us_mission}</a></li>
                                <li><a href="/blog" className="footer-link">{translations.about_us_blog}</a></li>
                                <li><a href="/trabaja-con-nosotros" className="footer-link">{translations.about_us_work}</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="footer-section">
                        <h4 className="footer-heading">{translations.footer_products}</h4>
                        <nav>
                            <ul className="footer-nav-list">
                                <li><a href="/nuevas-llegadas" className="footer-link">{translations.footer_products_new_arrivals}</a></li>
                                <li><a href="/mas-vendidos" className="footer-link">{translations.footer_products_best_sellers}</a></li>
                                <li><a href="/ofertas-especiales" className="footer-link">{translations.footer_products_special_offers}</a></li>
                                <li><a href="/recomendaciones" className="footer-link">{translations.footer_products_recommended}</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="footer-section">
                        <h4 className="footer-heading">{translations.footer_customer_service}</h4>
                        <nav>
                            <ul className="footer-nav-list">
                                <li><a href="/faq" className="footer-link">{translations.footer_customer_service_faq}</a></li>
                                <li><a href="/contacto" className="footer-link">{translations.footer_customer_service_contact_us}</a></li>
                                <li><a href="/envios" className="footer-link">{translations.footer_customer_service_shipping_info}</a></li>
                                <li><a href={translations.footer_customer_service_terms_conditions_url} className="footer-link">{translations.footer_customer_service_terms_conditions}</a></li>
                                <li><a href={translations.footer_customer_service_privacy_policy_url} className="footer-link">{translations.footer_customer_service_privacy_policy}</a></li>
                                <li><a href={translations.footer_customer_service_cookie_policy_url} className="footer-link">{translations.footer_customer_service_cookie_policy}</a></li>
                                <li><a href={translations.footer_customer_service_return_policy_url} className="footer-link">{translations.footer_customer_service_return_policy}</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="footer-section">
                        <h4 className="footer-heading">{translations.footer_more}</h4>
                        <nav>
                            <ul className="footer-nav-list">
                                <li><a href="/como-funciona-ia" className="footer-link">{translations.footer_more_how_our_ai_works}</a></li>
                                <li><a href="/personalizacion-automatizada" className="footer-link">{translations.footer_more_automated_personalization}</a></li>
                                <li><a href="/programa-lealtad" className="footer-link">{translations.footer_more_loyalty_program}</a></li>
                                <li><a href="/colaboraciones" className="footer-link">{translations.footer_more_collaborations}</a></li>
                                <li><a href="/guias-compra" className="footer-link">{translations.footer_more_buying_guides}</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="footer-section">
                        <h4 className="footer-heading">{translations.footer_social_networks}</h4>
                        <ul className="footer-social-list">
                            <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
                                <FontAwesomeIcon icon={faFacebookF} /></a></li>
                            <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
                                <FontAwesomeIcon icon={faInstagram} /></a></li>
                            <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
                                <FontAwesomeIcon icon={faXTwitter} /></a></li>
                            <li><a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
                                <FontAwesomeIcon icon={faLinkedin} /></a></li>
                            <li><a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
                                <FontAwesomeIcon icon={faYoutube} /></a></li>
                        </ul>
                        <h4 className="footer-heading">{translations.footer_payment_methods}</h4>
                        <ul className="footer-payment-list">
                            <li><button className="footer-payment-link" style={{ background: 'none', border: 'none', cursor: 'default' }}>
                                <FontAwesomeIcon icon={faCcVisa} />
                            </button></li>
                            <li><button className="footer-payment-link" style={{ background: 'none', border: 'none', cursor: 'default' }}>
                                <FontAwesomeIcon icon={faCcMastercard} />
                            </button></li>
                            <li><button className="footer-payment-link" style={{ background: 'none', border: 'none', cursor: 'default' }}>
                                <FontAwesomeIcon icon={faCcPaypal} />
                            </button></li>
                            <li><button className="footer-payment-link" style={{ background: 'none', border: 'none', cursor: 'default' }}>
                                <FaCcYape style={{ width: '30px', height: '30px', fill: 'transparent' }} />
                            </button></li>
                            <li><button className="footer-payment-link" style={{ background: 'none', border: 'none', cursor: 'default' }}>
                                <FaCcPlin style={{ width: '30px', height: '30px', fill: 'transparent' }} />
                            </button></li>
                        </ul>
                    </div>
                </div>
            </section>
        </footer>
    );
};

export default Footer;