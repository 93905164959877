import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Form, Input, Button, Typography, Divider, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";

const { Title } = Typography;

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [isSignUp, setIsSignUp] = useState(false);
    const [loading, setLoading] = useState(false);

    const { handleSignUp, handleLogin, externalLogin } = useAuth();

    const onFinish = async () => {
        setLoading(true);
        if (isSignUp) {
            const { success, error } = await handleSignUp(username, password, email);
            if (success) {
                notification.success({
                    message: "Registro exitoso",
                    description: "Por favor, revisa tu email para confirmar.",
                });
                setIsSignUp(false);
            } else {
                let errorMessage;
                switch (error.code) {
                    case "InvalidPasswordException":
                        errorMessage = "La contraseña debe tener al menos 8 caracteres, incluyendo una letra minúscula, una letra mayúscula, un número y un símbolo.";
                        break;
                    case "UsernameExistsException":
                        errorMessage = "Este nombre de usuario ya está registrado.";
                        break;
                    case "InvalidParameterException":
                        errorMessage = "Por favor, completa todos los campos correctamente.";
                        break;
                    default:
                        errorMessage = error.message || "Error en el registro.";
                }
                notification.error({
                    message: "Error en el registro",
                    description: errorMessage,
                });
            }
        } else {
            const { success, error } = await handleLogin(username, password);
            if (success) {
                window.location.href = "/";
            } else {
                let errorMessage;
                switch (error.code) {
                    case "UserNotFoundException":
                        errorMessage = "Usuario no encontrado. Verifica tu nombre de usuario.";
                        break;
                    case "NotAuthorizedException":
                        errorMessage = "Contraseña incorrecta. Intenta nuevamente.";
                        break;
                    case "UserNotConfirmedException":
                        errorMessage = "Por favor, confirma tu correo antes de iniciar sesión.";
                        break;
                    case "InvalidParameterException":
                        errorMessage = "Por favor, completa todos los campos correctamente.";
                        break;
                    default:
                        errorMessage = error.message || "Error en el inicio de sesión.";
                }
                notification.error({
                    message: "Error en el login",
                    description: errorMessage,
                });
            }
        }
        setLoading(false);
    };

    return (
        <div style={{ maxWidth: 400, margin: "auto", padding: "2rem" }}>
            <Title level={2}>{isSignUp ? "Registro" : "Iniciar sesión"}</Title>

            <Form layout="vertical" onFinish={onFinish}>
                <Form.Item label="Nombre de usuario" required>
                    <Input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                    />
                </Form.Item>

                <Form.Item label="Contraseña" required>
                    <Input.Password
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                </Form.Item>

                {isSignUp && (
                    <Form.Item label="Correo electrónico" required>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                        />
                    </Form.Item>
                )}

                <Form.Item>
                    <Button type="primary" htmlType="submit" block loading={loading}>
                        {isSignUp ? "Registrar" : "Login"}
                    </Button>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="link"
                        onClick={() => setIsSignUp(!isSignUp)}
                        block
                        disabled={loading}
                    >
                        {isSignUp ? "¿Ya tienes cuenta? Inicia sesión" : "¿No tienes cuenta? Regístrate"}
                    </Button>
                </Form.Item>
            </Form>

            <Divider>O inicia sesión con</Divider>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                    onClick={() => externalLogin("Google")}
                    type="default"
                    icon={<FontAwesomeIcon icon={faGoogle} />}
                >
                    Google
                </Button>
                <Button
                    onClick={() => externalLogin("Facebook")}
                    type="default"
                    icon={<FontAwesomeIcon icon={faFacebook} />}
                >
                    Facebook
                </Button>
            </div>
        </div>
    );
};

export default Login;
