import React from 'react';
import useTranslation from '../hooks/useTranslation';
import { Layout, Typography, Divider, Menu } from 'antd';

const { Sider } = Layout;
const { Title, Paragraph, Text } = Typography;

const TermsAndConditions = () => {
    const translations = useTranslation('terms_and_conditions');

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                width={300}
                breakpoint='md'
                collapsedWidth="0"
                style={{
                    background: '#fff',
                    position: 'sticky',
                    top: 0,
                    paddingTop: 30,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Menu mode="inline" defaultSelectedKeys={['info-general']} style={{ height: '100%', borderRight: 0 }}>
                    <Menu.Item key="info-general">
                        <a href="#info-general">{translations.tc_info_general}</a>
                    </Menu.Item>
                    <Menu.Item key="productos-transacciones">
                        <a href="#productos-transacciones">{translations.tc_products_transactions}</a>
                    </Menu.Item>
                    <Menu.Item key="derechos-responsabilidades">
                        <a href="#derechos-responsabilidades">{translations.tc_rights_responsibilities}</a>
                    </Menu.Item>
                    <Menu.Item key="aspectos-legales-contacto">
                        <a href="#aspectos-legales-contacto">{translations.tc_legal_contact}</a>
                    </Menu.Item>
                </Menu>
            </Sider>

            <Layout style={{ margin: 40 }}>
                <div style={{ minHeight: '100vh' }}>
                    <Typography>
                        <Title level={2} id="info-general">
                            {translations.tc_info_general}
                        </Title>

                        <Title level={3} id="introduccion">
                            1. {translations.tc_introduction}
                        </Title>
                        <Paragraph>{translations.tc_intro_paragraph1}</Paragraph>
                        <Paragraph>{translations.tc_intro_paragraph2}</Paragraph>
                        <Paragraph>{translations.tc_intro_paragraph3}</Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_intro_paragraph4_title}:</Text> {translations.tc_intro_paragraph4_content}
                        </Paragraph>

                        <Title level={3} id="definiciones">
                            2. {translations.tc_definitions_title}
                        </Title>
                        <Paragraph>
                            <Text strong>{translations.tc_definitions_user_title}:</Text> {translations.tc_definitions_user_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_definitions_company_title}:</Text> {translations.tc_definitions_company_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_definitions_products_services_title}:</Text> {translations.tc_definitions_products_services_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_definitions_content_title}:</Text> {translations.tc_definitions_content_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_definitions_order_title}:</Text> {translations.tc_definitions_order_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_definitions_terms_use_title}:</Text> {translations.tc_definitions_terms_use_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_definitions_personal_data_title}:</Text> {translations.tc_definitions_personal_data_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_definitions_privacy_policy_title}:</Text> {translations.tc_definitions_privacy_policy_content}
                        </Paragraph>

                        <Divider />

                        <Title level={2} id="productos-transacciones">
                            {translations.tc_products_transactions}
                        </Title>

                        <Title level={3} id="productos">
                            3. {translations.tc_products_services_title}
                        </Title>
                        <Paragraph>{translations.tc_products_services_description}</Paragraph>
                        <Paragraph>{translations.tc_products_availability}</Paragraph>
                        <Paragraph>{translations.tc_products_sales_limit}</Paragraph>

                        <Title level={3} id="precios">
                            4. {translations.tc_prices_payments_title}
                        </Title>
                        <Paragraph>{translations.tc_prices_changes}</Paragraph>
                        <Paragraph>{translations.tc_prices_inclusive}</Paragraph>
                        <Paragraph>{translations.tc_payment_methods}</Paragraph>
                        <Paragraph>{translations.tc_payment_processing}</Paragraph>

                        <Title level={3} id="proceso-pedido">
                            5. {translations.tc_ordering_process_title}
                        </Title>
                        <Paragraph>{translations.tc_ordering_process}</Paragraph>
                        <Paragraph>{translations.tc_ordering_confirmation}</Paragraph>
                        <Paragraph>{translations.tc_ordering_availability}</Paragraph>

                        <Title level={3} id="envio">
                            6. {translations.tc_shipping_delivery_title}
                        </Title>
                        <Paragraph>{translations.tc_shipping_options}</Paragraph>
                        <Paragraph>{translations.tc_shipping_time}</Paragraph>
                        <Paragraph>{translations.tc_shipping_tracking}</Paragraph>

                        <Title level={3} id="devoluciones">
                            7. {translations.tc_returns_refunds_title}
                        </Title>
                        <Paragraph>{translations.tc_returns_policy}</Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_returns_period_title}:</Text> {translations.tc_returns_period_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_returns_conditions_title}:</Text> {translations.tc_returns_conditions_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_returns_process_title}:</Text> {translations.tc_returns_process_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_returns_costs_title}:</Text> {translations.tc_returns_costs_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_returns_refund_title}:</Text> {translations.tc_returns_refund_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_returns_exceptions_title}:</Text> {translations.tc_returns_exceptions_content}
                        </Paragraph>
                        <Paragraph>
                            <Text italic>{translations.tc_returns_note}</Text>
                        </Paragraph>

                        <Divider />

                        <Title level={2} id="derechos-responsabilidades">
                            {translations.tc_rights_responsibilities}
                        </Title>

                        <Title level={3} id="propiedad-intellectual">
                            8. {translations.tc_intellectual_property_title}
                        </Title>
                        <Paragraph>{translations.tc_intellectual_property_content1}</Paragraph>
                        <Paragraph>{translations.tc_intellectual_property_content2}</Paragraph>
                        <Paragraph>{translations.tc_intellectual_property_content3}</Paragraph>

                        <Title level={3} id="responsabilidades">
                            9. {translations.tc_user_responsibilities_title}
                        </Title>
                        <Paragraph>{translations.tc_user_responsibilities_content}</Paragraph>
                        <Paragraph>
                            <ul>
                                {translations.tc_user_responsibilities_list ? translations.tc_user_responsibilities_list.map((item, index) => (
                                    <li key={index}>{item}</li>
                                )): ''}
                            </ul>
                        </Paragraph>
                        <Paragraph>{translations.tc_user_responsibilities_violation}</Paragraph>

                        <Title level={3} id="limitacion-responsabilidad">
                            10. {translations.tc_limitation_liability_title}
                        </Title>
                        <Paragraph>{translations.tc_limitation_liability_content1}</Paragraph>
                        <Paragraph>{translations.tc_limitation_liability_content2}</Paragraph>
                        <Paragraph>{translations.tc_limitation_liability_content3}</Paragraph>

                        <Divider />

                        <Title level={2} id="aspectos-legales-contacto">
                            {translations.tc_legal_contact}
                        </Title>

                        <Title level={3} id="privacidad">
                            11. {translations.tc_privacy_protection_title}
                        </Title>
                        <Paragraph>{translations.tc_privacy_protection_content1}</Paragraph>
                        <Paragraph>{translations.tc_privacy_protection_content2}</Paragraph>

                        <Title level={3} id="modificaciones">
                            12. {translations.tc_modifications_terms_title}
                        </Title>
                        <Paragraph>{translations.tc_modifications_terms_content1}</Paragraph>
                        <Paragraph>{translations.tc_modifications_terms_content2}</Paragraph>

                        <Title level={3} id="legislacion">
                            13. {translations.tc_governing_law_title}
                        </Title>
                        <Paragraph>{translations.tc_governing_law_content1}</Paragraph>
                        <Paragraph>{translations.tc_governing_law_content2}</Paragraph>

                        <Title level={3} id="contacto">
                            14. {translations.tc_contact_information_title}
                        </Title>
                        <Paragraph>{translations.tc_contact_information_content1}</Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_contact_email_title}:</Text> <a href={`mailto:${translations.tc_contact_email_content}`}>{translations.tc_contact_email_content}</a>
                        </Paragraph>
                        <Paragraph>{translations.tc_contact_information_content1}</Paragraph>

                        <Title level={3} id="clausulas-adicionales">
                            15. {translations.tc_additional_clauses_title}
                        </Title>
                        <Paragraph>
                            <Text strong>{translations.tc_affiliates_program_title}:</Text> {translations.tc_affiliates_program_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_international_sales_title}:</Text> {translations.tc_international_sales_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_cookies_use_title}:</Text> {translations.tc_cookies_use_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_promotions_offers_title}:</Text> {translations.tc_promotions_offers_content}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>{translations.tc_modification_services_title}:</Text> {translations.tc_modification_services_content}
                        </Paragraph>
                    </Typography>
                </div>
            </Layout>
        </Layout >
    );
};

export default TermsAndConditions;