import { ApolloClient } from '@apollo/client';
import cache from './cache';
import { createLink, httpLinkWithApiKey } from './links';

let authenticatedClient = null;

export const publicClient = new ApolloClient({
    link: httpLinkWithApiKey,
    cache,
});

export const createAuthenticatedClient = (sessionToken, authHandlers) => {
    if (authenticatedClient && sessionToken) {
        return authenticatedClient;
    }
    const link = createLink(sessionToken, authHandlers);
    authenticatedClient = new ApolloClient({
        link,
        cache,
    });
    return authenticatedClient;
};