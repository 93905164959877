import React, { createContext, useState, useEffect, useContext } from 'react';
import { signIn, signUp, signOut, externalLogin } from '../auth/cognito-auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const initialAuthState = JSON.parse(localStorage.getItem('auth_data')) || {
        guest_id: null,
        is_authenticated: false,
        session_token: null,
        sub: null,
        email: null
    };

    const [authData, setAuthData] = useState(initialAuthState);

    useEffect(() => {
        localStorage.setItem('auth_data', JSON.stringify(authData));
    }, [authData]);

    const handleSignUp = async (username, password, email) => {
        try {
            await signUp(username, password, email);
            return { success: true };
        } catch (error) {
            return { success: false, error };
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut();
            setAuthData({
                ...authData,
                is_authenticated: false,
                session_token: null,
                sub: null,
                email: null
            });
            return { success: true };
        } catch (error) {
            return { success: false, error };
        }
    };

    const handleLogin = async (username, password) => {
        try {
            const result = await signIn(username, password);
            setAuthData({
                ...authData,
                is_authenticated: true,
                session_token: result.idToken.jwtToken,
                sub: result.idToken.payload.sub,
                email: result.idToken.payload.email
            });
            return { success: true };
        } catch (error) {
            return { success: false, error };
        }
    };

    return (
        <AuthContext.Provider value={{
            authData,
            setAuthData,
            handleLogin,
            handleSignUp,
            handleSignOut,
            externalLogin
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);