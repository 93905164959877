import platform from 'platform';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../context/AuthContext';
import { INSERT_GUEST } from '../../graphql';

const MonitorGuest = () => {
    const { authData, setAuthData } = useAuth();
    const [insertGuestMutation] = useMutation(INSERT_GUEST);

    useEffect(() => {
        if (!authData.guest_id) {
            async function registerGuest() {
                try {
                    const response = await fetch('https://api.ipify.org?format=json');
                    const ipData = await response.json();
                    const { data } = await insertGuestMutation({
                        variables: {
                            input: {
                                operating_system: platform.os?.family || 'Unknown',
                                web_browser: platform.name || 'Unknown',
                                ip_address: ipData.ip,
                                initial_page: document.URL,
                                user_agent: navigator.userAgent,
                                referrer: document.referrer || 'Direct'
                            }
                        }
                    });
                    setAuthData({
                        ...authData,
                        guest_id: data.insertGuest.token_id
                    });
                } catch (error) {
                    console.error('Error registering the guest:', error);
                }
            }
            registerGuest();
        }
    }, [authData]);

    return (<></>);
}

export default MonitorGuest;
