import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Skeleton, Col, Row } from 'antd';
import useCategories from '../../hooks/useCategories';
import './CategoryList.css';

const { Meta } = Card;

const CategoryList = () => {
    const { category_param } = useParams();
    const { loading, error, data: categories } = useCategories();

    if (loading || error) return (
        <div className="category-list">
            <Skeleton active paragraph={{ rows: 4 }} />
        </div>
    );

    let category_id = 1;
    if (category_param && category_param.lastIndexOf('-') !== -1) {
        category_id = parseInt(category_param.substring(category_param.lastIndexOf('-') + 1));
    }
    const children = categories.filter(category => category.parent_id === category_id);

    if (children.length === 0) return (<div className="category-list"></div>);

    return (
        <div className="category-list">
            <Row gutter={[16, 20]}>
                {children.map(category => (
                    <Col xs={24} sm={12} md={8} lg={6} key={category.id}>
                        <Link to={`/${category.url_path}-${category.id}`}>
                            <Card
                                hoverable
                                cover={<Skeleton.Image active />}
                            >
                                <Meta
                                    title={category.name}
                                />
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default CategoryList;