import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { Badge, Select } from 'antd';
import { useAuth } from '../../context/AuthContext';
import { useCart } from '../../context/CartContext';
import { useSettings } from '../../context/SettingsContext';
import { useNavigate } from 'react-router-dom';
import { GET_LANGUAGE_LIST, GET_CURRENCY_LIST } from '../../graphql';
import Logo from '../../resources/logo.svg';
import MiniLogo from '../../resources/mini_logo.svg';
import './Settings.css';

const Settings = () => {
    const theme_background_color = process.env.REACT_APP_THEME_BACKGROUND_COLOR;
    const { authData } = useAuth();
    const { cart } = useCart();
    const { language, setLanguage, currency, setCurrency } = useSettings();
    const [loadLanguages, setLoadLanguages] = useState(false);
    const [loadCurrencies, setLoadCurrencies] = useState(false);
    const [productCount, setProductCount] = useState(cart?.data.length || 0);

    const { data: lanData, loading: languagesLoading } = useQuery(GET_LANGUAGE_LIST, {
        skip: !loadLanguages,
        fetchPolicy: 'cache-first',
    });

    const { data: currData, loading: currenciesLoading } = useQuery(GET_CURRENCY_LIST, {
        skip: !loadCurrencies,
        fetchPolicy: 'cache-first',
    });

    useEffect(() => {
        // Actualiza el contador del carrito
        setProductCount(cart?.data.length || 0);

        if (cart?.data.length > 0) {
            // Aquí puedes realizar una acción cuando se agregue un producto al carrito
            console.log('Pendiente de modificacion');
            // Agrega animaciones, notificaciones o cualquier otra acción aquí.
        }
    }, [cart]);

    const handleLanguageChange = (value) => {
        const selectedLanguage = lanData?.getLanguageList.find((lang) => lang.id === parseInt(value));
        if (selectedLanguage) {
            setLanguage(selectedLanguage);
            window.location.reload();
        }
    };

    const handleCurrencyChange = (value) => {
        const selectedCurrency = currData?.getCurrencyList.find((curr) => curr.id === parseInt(value));
        if (selectedCurrency) {
            setCurrency(selectedCurrency);
        }
    };

    const navigate = useNavigate();

    const handleCartClick = () => {
        navigate('/cart');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleLoginClick = () => {
        if (Boolean(authData.is_authenticated)) {
            navigate('/profile');
        } else {
            navigate('/login');
        }
    };

    return (
        <div className="top-bar" style={{ '--theme-background-color': theme_background_color }}>
            <img src={Logo} alt="ekadabra" className="main-logo" onClick={handleLogoClick} />
            <img src={MiniLogo} alt="ekadabra" className="mini-main-logo" onClick={handleLogoClick} />
            <div className="select-group">
                <Select
                    value={language?.id || ''}
                    onClick={() => setLoadLanguages(true)}
                    onChange={handleLanguageChange}
                    style={{ width: 60 }}
                    loading={languagesLoading}
                >
                    {lanData?.getLanguageList && lanData.getLanguageList.length > 0 ? (
                        lanData.getLanguageList.map((lang) => (
                            <Select.Option key={lang.id} value={lang.id}>
                                {lang.iso_code}
                            </Select.Option>
                        ))
                    ) : (
                        <Select.Option value={language.id}>{language.iso_code}</Select.Option>
                    )}
                </Select>
                <Select
                    value={currency?.id || ''}
                    onClick={() => setLoadCurrencies(true)}
                    onChange={handleCurrencyChange}
                    style={{ width: 100 }}
                    loading={currenciesLoading}
                >
                    {currData?.getCurrencyList && currData.getCurrencyList.length > 0 ? (
                        currData.getCurrencyList.map((curr) => (
                            <Select.Option key={curr.id} value={curr.id}>
                                {curr.currency_code} ({curr.currency_symbol})
                            </Select.Option>
                        ))
                    ) : (
                        <Select.Option value={currency.id}>
                            {currency.currency_code} ({currency.currency_symbol})
                        </Select.Option>
                    )}
                </Select>
            </div>
            <div className="icon-group">
                <div className="login-icon-container" onClick={handleLoginClick}>
                    {authData.is_authenticated ? (
                        <UserOutlined />
                    ) : (
                        <FontAwesomeIcon icon={faSignInAlt} />
                    )}
                </div>
                <div className="cart-icon-container" onClick={handleCartClick}>
                    <Badge count={productCount} size='small' offset={[0, 4]} style={{ backgroundColor: '#8a33b1' }}>
                        <ShoppingCartOutlined style={{ fontSize: '26px', color: '#ffffff' }} />
                    </Badge>
                </div>
            </div>
        </div>
    );
};

export default Settings;