import { useCallback } from 'react';
import useFormatNumber from './useFormatNumber';

const useProductPrice = () => {
    const formatNumber = useFormatNumber();

    const calculatePrice = useCallback((basePrice, currency) => {
        if (!basePrice || !currency) return '';

        const convertedPrice = basePrice * currency.exchange_rate_to_default;
        return formatNumber(convertedPrice, currency.format_pattern);
    }, [formatNumber]);

    return calculatePrice;
};

export default useProductPrice;