import React from 'react';
import useTranslation from '../hooks/useTranslation';
import Image404 from '../resources/404.svg';
import './NotFound.css';

const NotFound = () => {
    const translations = useTranslation('not_found');

    return (
        <div className="not-found-container">
          <div className="not-found-content">
            <h1 className="not-found-title">{translations.not_found_title}</h1>
            <p className="not-found-message">{translations.not_found_message}</p>
            <img
              src={Image404}
              alt="Page Not Found"
              className="not-found-image"
              width="300"
            />
            <a href="/" className="not-found-button">{translations.not_found_go_home}</a>
          </div>
        </div>
    );      
};

export default NotFound;