import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import awsConfig from '../aws-config';

const userPool = new CognitoUserPool({
    UserPoolId: awsConfig.Auth.userPoolId,
    ClientId: awsConfig.Auth.userPoolWebClientId,
});

export const signUp = (username, password, email) => {
    return new Promise((resolve, reject) => {
        const attributes = [new CognitoUserAttribute({ Name: 'email', Value: email })];
        userPool.signUp(username, password, attributes, null, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const signIn = (username, password) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
        const authDetails = new AuthenticationDetails({ Username: username, Password: password });
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (session) => {
                resolve(session);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

export const externalLogin = (provider) => {
    const url = `https://${awsConfig.Auth.domain}/login?response_type=token&client_id=${awsConfig.Auth.userPoolWebClientId}&redirect_uri=${window.location.origin}/login&identity_provider=${provider}`;
    window.location.href = url;
};

export const getCurrentSession = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            return reject(new Error("User is not logged in"));
        }
        cognitoUser.getSession((err, session) => {
            if (err) {
                reject(err);
            } else {
                resolve(session);
            }
        });
    });
};

export const signOut = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        cognitoUser.signOut();
    }
};

export const isAuthenticated = async () => {
    try {
        const session = await getCurrentSession();
        return session.isValid();
    } catch {
        return false;
    }
};