import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Row, Col, Pagination, Typography, Card, Skeleton, Select } from 'antd';
import { GET_PRODUCT_LIST } from '../../graphql';
import { useSettings } from '../../context/SettingsContext';
import useTranslation from '../../hooks/useTranslation';
import useProductPrice from '../../hooks/useProductPrice';
import './ProductList.css';

const { Title, Text } = Typography;
const { Option } = Select;

const ProductList = () => {
    const { category_param } = useParams();
    const { language, currency } = useSettings();
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOrder, setSortOrder] = useState('default');
    const translations = useTranslation('product');
    const page_size = process.env.REACT_APP_ITEMS_BY_CATEGORY;

    let category_id = null;
    if (category_param && category_param.lastIndexOf('-') !== -1) {
        category_id = parseInt(category_param.substring(category_param.lastIndexOf('-') + 1));
    }

    const sortMapping = {
        default: { order_by: 'id', order_direction: 'ASC' },
        newest: { order_by: 'created_at', order_direction: 'DESC' },
        a_to_z: { order_by: 'name', order_direction: 'ASC' },
        z_to_a: { order_by: 'name', order_direction: 'DESC' },
        lowest_to_highest: { order_by: 'base_price', order_direction: 'ASC' },
        highest_to_lowest: { order_by: 'base_price', order_direction: 'DESC' },
    };

    const { loading, error, data: products } = useQuery(GET_PRODUCT_LIST, {
        variables: {
            page: currentPage,
            page_size: page_size,
            category_id: category_id,
            order_by: sortMapping[sortOrder]['order_by'],
            order_direction: sortMapping[sortOrder]['order_direction'],
            lang_id: language.id,
        },
        fetchPolicy: 'cache-first',
    });

    const calculatePrice = useProductPrice();

    useEffect(() => {
        setCurrentPage(1);
    }, [category_id]);

    if (loading || error) {
        return (
            <div className="product-list">
                <Skeleton active paragraph={{ rows: 4 }} />
            </div>
        );
    }

    const total_products = products?.getProductList?.length || 0;

    if (total_products === 0) return (<div className="product-list"></div>);

    const handleSortChange = (value) => {
        setSortOrder(value);
        setCurrentPage(1);
    };

    return (
        <div className="product-list">
            <div className="product-list-header">
                <Row justify="space-between" align="middle">
                    <Col>
                        <Title level={5} className="product-count" style={{ margin: '10px 0' }}>
                            {(translations.product_count_label || "").replace('%s', total_products)}
                        </Title>
                    </Col>
                    <Col>
                        <Row align="middle" gutter={[8, 0]}>
                            <Col>
                                <Text>{translations.product_sort_by_label}</Text>
                            </Col>
                            <Col>
                                <Select
                                    value={sortOrder}
                                    onChange={handleSortChange}
                                    style={{ width: 200 }}
                                    defaultValue={"default"}
                                    size="large"
                                >
                                    <Option value="default">{translations.sort_product_option_default}</Option>
                                    <Option value="newest">{translations.sort_product_option_newest}</Option>
                                    <Option value="a_to_z">{translations.sort_product_option_a_to_z}</Option>
                                    <Option value="z_to_a">{translations.sort_product_option_z_to_a}</Option>
                                    <Option value="lowest_to_highest">{translations.sort_product_option_low_to_high}</Option>
                                    <Option value="highest_to_lowest">{translations.sort_product_option_high_to_low}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row gutter={[16, 20]}>
                {products?.getProductList.data.map(product => (
                    <Col xs={24} sm={12} md={8} lg={6} key={product.id}>
                        <Link to={`/${category_param}/${product.url_path}-${product.id}`}>
                            <Card
                                hoverable
                                cover={product.url_image ? 
                                    <img
                                        src={'http://ekadabra.com.s3-website-us-east-1.amazonaws.com/images/products/' + product.id + '/' + product.url_image}
                                        alt={product.name}
                                        loading="lazy"
                                    /> 
                                    : <Skeleton.Image style={{ width: 200, height: 200 }} active />}
                            >
                                <div className="product-card-content">
                                    <Skeleton loading={loading} active>
                                        <Title level={5} className="product-card-title" style={{ margin: '10px 0' }}>{product.name}</Title>
                                        <Text className="product-card-price">
                                            {calculatePrice(product.base_price, currency)}
                                        </Text>
                                    </Skeleton>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
            <Pagination
                current={currentPage}
                pageSize={page_size}
                total={total_products}
                onChange={page => setCurrentPage(page)}
                showSizeChanger={false}
                style={{ marginTop: '20px', textAlign: 'center' }}
            />
        </div>
    );
};

export default ProductList;