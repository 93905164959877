import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import useCategories from '../../hooks/useCategories';
import './Menu.css';

const Menu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const theme_second_color = process.env.REACT_APP_THEME_SECOND_COLOR;
    const theme_background_clear_color = process.env.REACT_APP_THEME_BACKGROUND_CLEAR_COLOR;

    const { loading, error, data: categories } = useCategories();

    if (loading || error) return <nav className="ecommerce-menu"></nav>;

    const buildMenu = (categories) => {
        const parents = categories.filter(category => category.parent_id === 1);
        const menu = parents.map(parent => ({
            ...parent,
            children: categories.filter(child => child.parent_id === parent.id),
        }));
        return menu;
    };
    const menuItems = buildMenu(categories);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsMenuOpen(false);
    };

    return (
        <nav className={`ecommerce-menu ${isMenuOpen ? 'open' : ''}`} style={{
            '--theme-second-color': theme_second_color,
            '--theme-background-clear-color': theme_background_clear_color
        }}>
            <ul className={window.innerWidth <= 768 ? 'mobile-menu' : 'desktop-menu'}>
                {window.innerWidth <= 768 && (
                    <li className="hamburger-icon" onClick={handleMenuToggle}>
                        <FontAwesomeIcon icon={faBars} />
                    </li>
                )}
                {(isMenuOpen || window.innerWidth > 768) && menuItems.map(parent => (
                    <li key={parent.id} className="parent-category" onClick={() => handleNavigation(`/${parent.url_path}-${parent.id}`)}>
                        <span>
                            {parent.name}
                        </span>
                        {window.innerWidth > 768 && parent.children.length > 0 && (
                            <ul className="submenu">
                                {parent.children.map(child => (
                                    <li key={child.id} onClick={(e) => {
                                        e.stopPropagation();
                                        handleNavigation(`/${child.url_path}-${child.id}`);
                                    }}>
                                        <span>{child.name}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Menu;