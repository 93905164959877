import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ORDER_LIST } from '../graphql';
import { useAuth } from '../context/AuthContext';
import { useClients } from '../context/ApolloClientProvider';
import { List, Skeleton, Typography, Alert, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const Profile = () => {
    const navigate = useNavigate();
    const { authData, handleSignOut } = useAuth();
    const { authenticatedClient } = useClients();

    useEffect(() => {
        if (!authData.is_authenticated) {
            navigate('/login');
        }
    }, [authData]);

    const onSignOut = async () => {
        const result = await handleSignOut();
        if (result.success) {
            navigate('/login');
        }
    };

    const { loading, error, data } = useQuery(GET_ORDER_LIST, {
        client: authenticatedClient,
        variables: { customer_id: 1 },
        skip: !authData.is_authenticated,
    });

    if (loading) {
        return <Skeleton active paragraph={{ rows: 4 }} />;
    }

    if (error) {
        return <Alert message="Error" description={"Error al cargar las órdenes: " + error.message} type="error" showIcon />;
    }

    const orders = data?.getOrderList || [];

    return (
        <div style={{ maxWidth: 800, margin: 'auto', padding: '2rem' }}>
            <Title level={3}>Tus Órdenes</Title>
            <Button type="primary" onClick={onSignOut} style={{ marginBottom: '1rem' }}>
                Cerrar sesión
            </Button>
            <List
                itemLayout="horizontal"
                dataSource={orders}
                renderItem={(order) => (
                    <List.Item>
                        <List.Item.Meta
                            title={`Orden #${order.id}`}
                            description={`Método de Pago: ${order.payment_method_id} | Carrier: ${order.carrier_id} | Carrito: ${order.cart_id}`}
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

export default Profile;