import React from 'react';
import CategoryBreadcrumb from "../components/Category/CategoryBreadcrumb";
import CategoryList from "../components/Category/CategoryList";

const Categories = () => {
    return (
        <div>
            <CategoryBreadcrumb />
            <CategoryList />
        </div>
    );
};
  
export default Categories;