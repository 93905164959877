import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ShoppingOutlined, CheckOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

import { Layout, Row, Col, Input, Button, List, Card, Form, Radio, Select, Checkbox, Skeleton, Space, Steps, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import {
    GET_COUNTRY_LIST,
    GET_DEPARTMENT_LIST_BY_COUNTRY,
    GET_CITY_LIST_BY_DEPARTMENT,
    GET_DISTRICT_LIST_BY_CITY,
    UPDATE_CART_PRODUCT,
    DELETE_CART_PRODUCT,
    GET_CART_PRODUCT_DETAILS,
    GET_DISCOUNT_DETAIL,
    GET_CARRIER_LIST,
    GET_PAYMENT_METHOD_LIST,
    INSERT_ORDER
} from '../../graphql';

import { useAuth } from '../../context/AuthContext';
import { useCart } from '../../context/CartContext';
import { useSettings } from '../../context/SettingsContext';
import useTranslation from '../../hooks/useTranslation';
import useProductPrice from '../../hooks/useProductPrice';

import './Cart.css';

const { Option } = Select;
const { Content } = Layout;
const { Step } = Steps;

const Cart = () => {
    // Context and hooks
    const { language, currency } = useSettings();
    const { authData } = useAuth();
    const { cart, setCart, quantities } = useCart();
    const navigate = useNavigate();
    const translations = useTranslation('cart');
    const calculatePrice = useProductPrice();

    // State variables
    const [checkedTerms, setCheckedTerms] = useState({ terms: false, privacy: false });
    const [shippingMethod, setShippingMethod] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [applyPassword, setApplyPassword] = useState(false);
    const [cartLength, setCartLength] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    const [countryId, setCountryId] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [districtId, setDistrictId] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [isDepartmentEnabled, setIsDepartmentEnabled] = useState(false);
    const [isCityEnabled, setIsCityEnabled] = useState(false);
    const [isDistrictEnabled, setIsDistrictEnabled] = useState(false);
    const [couponCode, setCouponCode] = useState('');

    // Mutations and queries
    const [updateCartProduct] = useMutation(UPDATE_CART_PRODUCT);
    const [deleteCartProduct] = useMutation(DELETE_CART_PRODUCT);
    const [insertOrder] = useMutation(INSERT_ORDER);
    const [getCartProductDetails, { data: cartProductDetailsData }] = useLazyQuery(GET_CART_PRODUCT_DETAILS);
    const [getCountries, { loading: loadingCountries, data: countriesData }] = useLazyQuery(GET_COUNTRY_LIST);
    const [getDepartments, { loading: loadingDepartments, data: departmentsData }] = useLazyQuery(GET_DEPARTMENT_LIST_BY_COUNTRY);
    const [getCities, { loading: loadingCities, data: citiesData }] = useLazyQuery(GET_CITY_LIST_BY_DEPARTMENT);
    const [getDistricts, { loading: loadingDistricts, data: districtsData }] = useLazyQuery(GET_DISTRICT_LIST_BY_CITY);
    const [getCarriers, { loading: loadingCarriers, data: carriersData }] = useLazyQuery(GET_CARRIER_LIST);
    const [getPaymentMethods, { loading: loadingPayments, data: paymentsData }] = useLazyQuery(GET_PAYMENT_METHOD_LIST);
    const [getDiscountDetail, { loading: loadingDiscount, data: discountData }] = useLazyQuery(GET_DISCOUNT_DETAIL);

    const [userForm] = Form.useForm();
    const [shippingForm] = Form.useForm();

    const debounceUpdateCartProduct = useMemo(
        () => debounce((product_id, quantity) => {
            updateCartProduct({
                variables: {
                    input: {
                        token_cart_id: cart.id,
                        product_id,
                        quantity,
                    },
                },
            }).then(({ data }) => {
                if (data?.updateCartProduct?.success) {
                    notification.success({
                        message: translations.cart_summary_success_update_notif,
                        description: translations.cart_summary_success_update_desc,
                    });
                } else {
                    notification.error({
                        message: translations.cart_summary_error_update_notif,
                        description: translations.cart_summary_error_update_desc,
                    });
                }
            }).catch(error => {
                notification.error({
                    message: translations.cart_summary_error_update_notif,
                    description: translations.cart_summary_error_update_desc,
                });
            });
        }, 500),
        [cart.id, updateCartProduct, translations]
    );

    const handleQuantityChange = (item, value) => {
        setDiscount(null);
        setCart(prevCart => {
            const updatedData = prevCart.data.map(cartItem =>
                cartItem.product_id === item.product_id
                    ? { ...cartItem, quantity: value }
                    : cartItem
            );
            return { ...prevCart, data: updatedData };
        });
        debounceUpdateCartProduct(item.product_id, value);
    };

    const handleRemoveItem = (item) => {
        deleteCartProduct({
            variables: {
                input: {
                    token_cart_id: cart.id,
                    product_id: item.product_id,
                },
            }
        }).then(({ data }) => {
            if (data?.deleteCartProduct?.success) {
                setCart(prevCart => {
                    const updatedData = prevCart.data.filter(cartItem => cartItem.product_id !== item.product_id);
                    return { ...prevCart, data: updatedData };
                });
                notification.success({
                    message: translations.cart_summary_success_delete_notif,
                    description: translations.cart_summary_success_delete_desc,
                });
            } else {
                notification.error({
                    message: translations.cart_summary_error_delete_notif,
                    description: translations.cart_summary_error_delete_desc,
                });
            }
        })
            .catch(error => {
                notification.error({
                    message: translations.cart_summary_error_delete_notif,
                    description: translations.cart_summary_error_delete_desc,
                });
            });
    };

    const handleApplyDiscount = () => {
        if (!couponCode) {
            notification.error({
                message: translations.cart_summary_error_discount_notif,
                description: translations.cart_summary_error_discount_desc,
            });
            return;
        }
        getDiscountDetail({
            variables: {
                sub: authData.sub,
                carrier_id: shippingMethod,
                payment_method_id: paymentMethod,
                currency_id: currency.id,
                order_amount: calculateTotal() || 0,
                coupon: couponCode,
            }
        });
    }

    const calculateTotalWeight = () => {
        return cart.data.reduce((total, item, index) => {
            const productTotal = item.weight * quantities[index];
            return total + productTotal;
        }, 0);
    };

    const calculateSubtotalAmount = () => {
        return cart.data.reduce((total, item, index) => {
            const productTotal = item.product_price * quantities[index];
            return total + productTotal;
        }, 0);
    };

    const calculateShippingCost = (rulesJson) => {
        if (!rulesJson) return 0;
    
        const rules = JSON.parse(rulesJson);
        const subtotal_amount = calculateSubtotalAmount();
        const total_weight = calculateTotalWeight()/1000;
    
        return rules.reduce((maxPrice, rule) => {
            const meetsAmountRule = rule.type === 'AMOUNT' && subtotal_amount >= rule.min_value && subtotal_amount < rule.max_value;
            const meetsWeightRule = rule.type === 'WEIGHT' && total_weight >= rule.min_value && total_weight < rule.max_value;
            if (meetsAmountRule || meetsWeightRule) {
                return Math.max(maxPrice, rule.price);
            }
            return maxPrice;
        }, 0);
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotalAmount();
        let shippingCost = 0;
        if (carriersData && carriersData.getCarrierList && shippingMethod) {
            const selectedCarrier = carriersData.getCarrierList.find(carrier => carrier.id === shippingMethod);
            if (selectedCarrier) {
                shippingCost = calculateShippingCost(selectedCarrier.rules);
            }
        }
        return discount ? subtotal + shippingCost - discount : subtotal + shippingCost;
    };

    const validateStep = (step) => {
        if (step === 0) {
            return userForm.validateFields().then(() => true).catch(() => {
                notification.error({
                    message: translations.cart_summary_error_validation_notif,
                    description: translations.cart_summary_error_validation_user_info_desc,
                });
                return false;
            });
        } else if (step === 1) {
            return shippingForm.validateFields().then(() => true).catch(() => {
                notification.error({
                    message: translations.cart_summary_error_validation_notif,
                    description: translations.cart_summary_error_validation_ship_info_desc,
                });
                return false;
            });
        } else if (step === 2) {
            if (!shippingMethod) {
                notification.error({
                    message: translations.cart_summary_error_validation_notif,
                    description: translations.cart_summary_error_validation_shipping_method_desc,
                });
                return Promise.resolve(false);
            }
            return Promise.resolve(true);
        } else if (step === 3) {
            if (!paymentMethod) {
                notification.error({
                    message: translations.cart_summary_error_validation_notif,
                    description: translations.cart_summary_error_validation_payment_method_desc,
                });
                return Promise.resolve(false);
            }
            return Promise.resolve(true);
        }
        return Promise.resolve(true);
    };

    const scrollToForm = (step) => {
        const formId = `form-step-${step}`;
        const element = document.getElementById(formId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const nextStep = () => {
        validateStep(currentStep).then(isValid => {
            if (isValid) {
                if (currentStep === 0) {
                    const userFormData = userForm.getFieldsValue();
                    setCart(prevCart => ({
                        ...prevCart,
                        user_information: userFormData,
                    }));
                }
                if (currentStep === 1) {
                    const shippingFormData = shippingForm.getFieldsValue();
                    setCart(prevCart => ({
                        ...prevCart,
                        shipping_information: shippingFormData,
                    }));
                }
                setCurrentStep(prev => {
                    const newStep = Math.min(prev + 1, 4);
                    scrollToForm(newStep);
                    return newStep;
                });
            }
        });
    };

    const prevStep = () => {
        setCurrentStep(prev => Math.max(prev - 1, 0));
        scrollToForm(currentStep - 1);
    };

    const handleStepClick = (step) => {
        validateStep(currentStep).then(isValid => {
            if (isValid) {
                setCurrentStep(step);
                scrollToForm(step);
            }
        });
    };

    const handleCheckboxChange = (type) => {
        setCheckedTerms({ ...checkedTerms, [type]: !checkedTerms[type] });
    };

    const handleCountryChange = (value) => {
        setCountryId(value.key);
        setIsDepartmentEnabled(false);
        setIsCityEnabled(false);
        setIsDistrictEnabled(false);
        shippingForm.resetFields(['department', 'city', 'district']);
        getDepartments({ variables: { country_id: value.key } });
    };

    const handleDepartmentChange = (value) => {
        setDepartmentId(value.key);
        setIsCityEnabled(false);
        setIsDistrictEnabled(false);
        shippingForm.resetFields(['city', 'district']);
        getCities({ variables: { department_id: value.key } });
    };

    const handleCityChange = (value) => {
        setCityId(value.key);
        setIsDistrictEnabled(false);
        shippingForm.resetFields(['district']);
        getDistricts({ variables: { city_id: value.key } });
    };

    const getImagePath = (folder, code) => {
        try {
            return require(`../../resources/${folder}/${code}.svg`);
        } catch (err) {
            console.error("Error loading image: ", err);
            return null;
        }
    };

    const handleConfirmPurchase = () => {
        const subtotalAmount = calculateSubtotalAmount();
        const shippingCost = carriersData && carriersData.getCarrierList && shippingMethod ? calculateShippingCost(carriersData.getCarrierList.find(carrier => carrier.id === shippingMethod)?.rules) : 0;
        insertOrder({
            variables: {
                input: {
                    sub: authData.sub,
                    token_guest_id: authData.guest_id,
                    token_cart_id: cart.id,
                    payment_method_id: paymentMethod,
                    carrier_id: shippingMethod,
                    currency_id: currency.id,
                    lang_id: language.id,
                    user_information: {
                        email: cart.user_information.email,
                        id_number: cart.user_information.id,
                        first_name: cart.user_information.firstName,
                        last_name: cart.user_information.lastName,
                        phone: cart.user_information.phone,
                    },
                    shipping_information: {
                        alias: 'Default',
                        address: cart.shipping_information.address,
                        reference: cart.shipping_information.reference || null,
                        country_id: cart.shipping_information.country ? cart.shipping_information.country.value : null,
                        department_id: cart.shipping_information.department ? cart.shipping_information.department.value : null,
                        city_id: cart.shipping_information.city ? cart.shipping_information.city.value : null,
                        district_id: cart.shipping_information.district ? cart.shipping_information.district.value : null,
                    },
                    order_lines: cart.data.map((product) => ({
                        product_id: product.product_id,
                        quantity: product.quantity,
                    })),
                    order_summary: {
                        subtotal_amount: subtotalAmount,
                        discount_amount: discount || 0,
                        shipping_amount: shippingCost,
                        total_amount: calculateTotal(),
                    },
                },
            },
        })
            .then(({ data }) => {
                if (data?.insertOrder?.success) {
                    const newCart = { ...cart };
                    newCart['data'] = [];
                    setCart(newCart);
                    notification.success({
                        message: translations.cart_summary_success_insert_order_notif,
                        description: translations.cart_summary_success_insert_order_desc,
                    });
                    navigate('/'); //('/order-confirmation');
                } else {
                    notification.error({
                        message: translations.cart_summary_error_insert_order_notif,
                        description: translations.cart_summary_error_insert_order_desc,
                    });
                }
            })
            .catch(error => {
                notification.error({
                    message: translations.cart_summary_error_insert_order_notif,
                    description: translations.cart_summary_error_insert_order_desc,
                });
            });
    };

    useEffect(() => {
        if (cartProductDetailsData?.getCartProductDetails) {
            setCart(prevCart => {
                const updatedData = prevCart.data.map(cartItem => {
                    const additionalInfo = cartProductDetailsData.getCartProductDetails.find(product => product.id === cartItem.product_id);
                    const product_price = additionalInfo.base_price;
                    if (additionalInfo.reduction_price != null) {
                        product_price -= additionalInfo.reduction_price;
                    } else if (additionalInfo.reduction_percent != null) {
                        product_price -= (additionalInfo.base_price * (additionalInfo.reduction_percent / 100));
                    }
                    return additionalInfo
                        ? {
                            ...cartItem,
                            product_name: additionalInfo.name,
                            product_price: product_price,
                            stock_quantity: additionalInfo.stock_quantity,
                            weight: additionalInfo.weight,
                            full_url_path: additionalInfo.full_url_path
                        }
                        : cartItem;
                });
                return { ...prevCart, data: updatedData };
            });
        }
    }, [cartProductDetailsData]);

    useEffect(() => {
        if (authData.is_authenticated) {
            setCart(prevCart => ({
                ...prevCart,
                user_information: {
                    ...prevCart.user_information,
                    email: authData.email
                }
            }));
        }
    }, [authData]);

    useEffect(() => {
        if (cart?.data?.length > 0) {
            if (cart.data.length > cartLength) {
                const productIds = cart.data.map(item => item.product_id).join(',');
                getCartProductDetails({
                    variables: {
                        product_ids: productIds,
                        lang_id: language.id,
                    }
                });
            }
            setCartLength(cart.data.length);
        }
        if (cart?.user_information) {
            userForm.setFieldsValue(cart.user_information);
        }
        if (cart?.shipping_information) {
            setCountryId(cart.shipping_information.country.value);
            if (cart.shipping_information?.department) {
                setDepartmentId(cart.shipping_information.department.value);
                setIsDepartmentEnabled(true)
            } else {
                setDepartmentId(null)
                setIsDepartmentEnabled(false)
            }
            if (cart.shipping_information?.city) {
                setCityId(cart.shipping_information.city.value);
                setIsCityEnabled(true)
            } else {
                setCityId(null)
                setIsCityEnabled(false)
            }
            if (cart.shipping_information?.district) {
                setDistrictId(cart.shipping_information.district.value);
                setIsDistrictEnabled(true)
            } else {
                setDistrictId(null)
                setIsDistrictEnabled(false)
            }
            shippingForm.setFieldsValue({
                ...cart.shipping_information,
                country: {
                    key: cart.shipping_information.country.key,
                    value: cart.shipping_information.country.value,
                    label: cart.shipping_information.country.label,
                },
                department: cart.shipping_information?.department ? {
                    key: cart.shipping_information.department.key,
                    value: cart.shipping_information.department.value,
                    label: cart.shipping_information.department.label,
                } : null,
                city: cart.shipping_information?.city ? {
                    key: cart.shipping_information.city?.key,
                    value: cart.shipping_information.city?.value,
                    label: cart.shipping_information.city?.label,
                } : null,
                district: cart.shipping_information?.district ? {
                    key: cart.shipping_information.district?.key || null,
                    value: cart.shipping_information.district?.value || null,
                    label: cart.shipping_information.district?.label || '',
                } : null,
            });
        }
    }, [cart, userForm, shippingForm]);

    useEffect(() => {
        let geography_codes = [];
        districtId && geography_codes.push('DISTRICT_' + districtId);
        cityId && geography_codes.push('CITY_' + cityId);
        departmentId && geography_codes.push('DEPARTMENT_' + departmentId);
        countryId && geography_codes.push('COUNTRY_' + countryId);
        if (geography_codes.length !== 0) {
            getCarriers({
                variables: {
                    geography_codes: geography_codes.join(','),
                    lang_id: language.id,
                },
            });
        }
        getPaymentMethods({ variables: { lang_id: language.id } });
    }, [countryId, departmentId, cityId, districtId]);

    useEffect(() => {
        if (departmentsData && departmentsData.getDepartmentListByCountry) {
            if (departmentsData.getDepartmentListByCountry.length === 0) {
                setIsDepartmentEnabled(false);
            } else {
                setIsDepartmentEnabled(true);
            }
        }
        if (citiesData && citiesData.getCityListByDepartment) {
            if (citiesData.getCityListByDepartment.length === 0) {
                setIsCityEnabled(false);
            } else {
                setIsCityEnabled(true);
            }
        }
        if (districtsData && districtsData.getDistrictListByCity) {
            if (districtsData.getDistrictListByCity.length === 0) {
                setIsDistrictEnabled(false);
            } else {
                setIsDistrictEnabled(true);
            }
        }
    }, [departmentsData, citiesData, districtsData]);

    useEffect(() => {
        if (discountData?.getDiscountDetail) {
            const { type_discount, value_discount } = discountData.getDiscountDetail;
            if (type_discount === null || value_discount === null) {
                notification.error({
                    message: translations.cart_summary_error_discount_applied_notif,
                    description: translations.cart_summary_error_discount_applied_desc,
                });
                return;
            }
            let discountValue = 0;
            if (type_discount === 'PERCENTAGE') {
                discountValue = calculateTotal() * (value_discount / 100);
            } else if (type_discount === 'FIXED') {
                discountValue = value_discount;
            }
            setDiscount(discountValue);
            notification.success({
                message: translations.cart_summary_success_discount_applied_notif,
                description: translations.cart_summary_success_discount_applied_desc,
            });
        } else if (discountData) {
            notification.error({
                message: translations.cart_summary_error_discount_applied_notif,
                description: translations.cart_summary_error_discount_applied_desc,
            });
        }
    }, [discountData]);

    return (
        <Content style={{ padding: '20px' }}>
            <Steps current={currentStep} style={{ marginBottom: '20px' }}>
                <Step title={translations.cart_summary_user_information} onClick={() => handleStepClick(0)} />
                <Step title={translations.cart_summary_shipping_information} onClick={() => handleStepClick(1)} />
                <Step title={translations.cart_summary_shipping_method} onClick={() => handleStepClick(2)} />
                <Step title={translations.cart_summary_payment_method} onClick={() => handleStepClick(3)} />
                <Step title={translations.cart_summary_confirm_order} onClick={() => handleStepClick(4)} />
            </Steps>
            <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={24} md={8}>
                    <Card title={translations.cart_summary_user_information} id="form-step-0">
                        <Form layout="vertical" form={userForm}>
                            <Form.Item
                                label={translations.cart_summary_id}
                                name="id"
                                rules={[{ required: true, message: translations.cart_summary_id_request }]}
                                style={{ marginBottom: '8px' }}
                            >
                                <Input placeholder={translations.cart_summary_id_dummy} disabled={currentStep !== 0} />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label={translations.cart_summary_first_name}
                                        name="firstName"
                                        rules={[{ required: true, message: translations.cart_summary_first_name_request }]}
                                        style={{ marginBottom: '8px' }}
                                    >
                                        <Input placeholder={translations.cart_summary_first_name_dummy} disabled={currentStep !== 0} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={translations.cart_summary_last_name}
                                        name="lastName"
                                        rules={[{ required: true, message: translations.cart_summary_last_name_request }]}
                                        style={{ marginBottom: '8px' }}
                                    >
                                        <Input placeholder={translations.cart_summary_last_name_dummy} disabled={currentStep !== 0} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label={translations.cart_summary_phone_number}
                                name="phone"
                                rules={[{ required: true, message: translations.cart_summary_phone_number_request }]}
                                style={{ marginBottom: '8px' }}
                            >
                                <Input placeholder={translations.cart_summary_phone_number_dummy} disabled={currentStep !== 0} />
                            </Form.Item>
                            <Form.Item
                                label={translations.cart_summary_email}
                                name="email"
                                rules={[{ type: 'email', required: true, message: translations.cart_summary_email_request }]}
                                style={{ marginBottom: '8px' }}
                            >
                                <Input
                                    placeholder={translations.cart_summary_email_dummy}
                                    disabled={authData.is_authenticated || currentStep !== 0}
                                    value={authData.email || undefined}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Checkbox
                                    disabled={authData.is_authenticated || currentStep !== 0}
                                    onChange={(e) => setApplyPassword(e.target.checked)}
                                >
                                    {translations.cart_summary_check_custom_pwd}
                                </Checkbox>
                            </Form.Item>
                            {applyPassword && (
                                <Form.Item
                                    label={translations.cart_summary_pwd}
                                    name="password"
                                    rules={[{ required: true, message: translations.cart_summary_pwd_request }]}
                                >
                                    <Input.Password placeholder={translations.cart_summary_pwd_dummy} disabled={currentStep !== 0} />
                                </Form.Item>
                            )}
                            {currentStep === 0 && (
                                <Button type="primary" onClick={nextStep}>{translations.cart_summary_next}</Button>
                            )}
                        </Form>
                    </Card>

                    <Card title={translations.cart_summary_shipping_information} style={{ marginTop: '16px' }} id="form-step-1">
                        <Form layout="vertical" form={shippingForm}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label={translations.cart_summary_country}
                                        name="country"
                                        rules={[{ required: true, message: translations.cart_summary_country_request }]}
                                        style={{ marginBottom: '8px' }}
                                    >
                                        <Select
                                            placeholder={translations.cart_summary_country_dummy}
                                            loading={loadingCountries}
                                            onFocus={() => getCountries()}
                                            onChange={handleCountryChange}
                                            disabled={currentStep !== 1}
                                            labelInValue
                                            showSearch
                                            optionFilterProp="children"
                                        >
                                            {countriesData?.getCountryList.map(country => (
                                                <Option key={country.id} value={country.id}>{country.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    {isDepartmentEnabled &&
                                        <Form.Item
                                            label={translations.cart_summary_state}
                                            name="department"
                                            rules={[{ required: true, message: translations.cart_summary_state_request }]}
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <Select
                                                placeholder={translations.cart_summary_state_dummy}
                                                loading={loadingDepartments}
                                                onFocus={() => {
                                                    if (countryId) {
                                                        getDepartments({ variables: { country_id: countryId } });
                                                    }
                                                }}
                                                onChange={handleDepartmentChange}
                                                disabled={currentStep !== 1}
                                                labelInValue
                                                showSearch
                                                optionFilterProp="children"
                                            >
                                                {departmentsData?.getDepartmentListByCountry.map(department => (
                                                    <Option key={department.id} value={department.id}>{department.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    }
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {isCityEnabled &&
                                        <Form.Item
                                            label={translations.cart_summary_city}
                                            name="city"
                                            rules={[{ required: true, message: translations.cart_summary_city_request }]}
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <Select
                                                placeholder={translations.cart_summary_city_dummy}
                                                loading={loadingCities}
                                                onFocus={() => {
                                                    if (departmentId) {
                                                        getCities({ variables: { department_id: departmentId } });
                                                    }
                                                }}
                                                onChange={handleCityChange}
                                                disabled={currentStep !== 1}
                                                labelInValue
                                                showSearch
                                                optionFilterProp="children"
                                            >
                                                {citiesData?.getCityListByDepartment.map(city => (
                                                    <Option key={city.id} value={city.id}>{city.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    }
                                </Col>
                                <Col span={12}>
                                    {isDistrictEnabled &&
                                        <Form.Item
                                            label={translations.cart_summary_district}
                                            name="district"
                                            rules={[{ required: true, message: translations.cart_summary_district_request }]}
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <Select
                                                placeholder={translations.cart_summary_district_dummy}
                                                loading={loadingDistricts}
                                                onFocus={() => {
                                                    if (cityId) {
                                                        getDistricts({ variables: { city_id: cityId } });
                                                    }
                                                }}
                                                disabled={currentStep !== 1}
                                                labelInValue
                                                showSearch
                                                optionFilterProp="children"
                                            >
                                                {districtsData?.getDistrictListByCity.map(district => (
                                                    <Option key={district.id} value={district.id}>{district.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    }
                                </Col>
                            </Row>
                            <Form.Item
                                label={translations.cart_summary_address}
                                name="address"
                                rules={[{ required: true, message: translations.cart_summary_address_request }]}
                                style={{ marginBottom: '8px' }}
                            >
                                <Input placeholder={translations.cart_summary_address_dummy} disabled={currentStep !== 1} />
                            </Form.Item>
                            <Form.Item
                                label={translations.cart_summary_reference}
                                name="reference"
                                rules={[{ required: false, message: translations.cart_summary_reference_request }]}
                            >
                                <TextArea placeholder={translations.cart_summary_reference_dummy} disabled={currentStep !== 1} rows={3} />
                            </Form.Item>
                            {currentStep === 1 && (
                                <>
                                    <Button type="default" onClick={prevStep} style={{ marginRight: '8px' }}>
                                        {translations.cart_summary_back}
                                    </Button>
                                    <Button type="primary" onClick={nextStep}>
                                        {translations.cart_summary_next}
                                    </Button>
                                </>
                            )}
                        </Form>
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={16}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={12}>
                            <Card title={translations.cart_summary_shipping_method} id="form-step-2">
                                <Radio.Group
                                    onChange={(e) => setShippingMethod(e.target.value)}
                                    value={shippingMethod}
                                    disabled={currentStep !== 2}
                                >
                                    <Space direction="vertical">
                                        {carriersData?.getCarrierList.map(carrier => (
                                            <Row align="middle" key={carrier.id}>
                                                <Radio value={carrier.id} className="carrier-radio">
                                                    {loadingCarriers || !getImagePath('carriers', carrier.code) ? (
                                                        <Skeleton.Image style={{ width: 35, height: 35, marginLeft: '8px' }} />
                                                    ) : (
                                                        <img src={getImagePath('carriers', carrier.code)} alt={carrier.name} style={{ width: 35, height: 35, marginLeft: '8px' }} />
                                                    )}
                                                    <span className="carrier-name">{carrier.name}</span>
                                                </Radio>
                                            </Row>
                                        ))}
                                        <Row align="bottom">
                                            {currentStep === 2 && (
                                                <>
                                                    <Button type="default" onClick={prevStep} style={{ marginRight: '8px', marginTop: '16px' }}>
                                                        {translations.cart_summary_back}
                                                    </Button>
                                                    <Button type="primary" onClick={nextStep}>
                                                        {translations.cart_summary_next}
                                                    </Button>
                                                </>
                                            )}
                                        </Row>
                                    </Space>
                                </Radio.Group>
                            </Card>
                        </Col>

                        <Col xs={24} sm={12} md={12}>
                            <Card title={translations.cart_summary_payment_method} id="form-step-3">
                                <Radio.Group
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                    value={paymentMethod}
                                    disabled={currentStep !== 3}
                                >
                                    <Space direction="vertical">
                                        {paymentsData?.getPaymentMethodList.map(payment => (
                                            <Row align="middle" key={payment.id}>
                                                <Radio value={payment.id} className="payment-radio">
                                                    {loadingPayments || !getImagePath('payment_methods', payment.code) ? (
                                                        <Skeleton.Image style={{ width: 35, height: 35, marginLeft: '8px' }} />
                                                    ) : (
                                                        <img src={getImagePath('payment_methods', payment.code)} alt={payment.name} style={{ width: 35, height: 35, marginLeft: '8px' }} />
                                                    )}
                                                    <span className="payment-name">{payment.name}</span>
                                                </Radio>
                                            </Row>
                                        ))}
                                        <Row align="bottom">
                                            {currentStep === 3 && (
                                                <>
                                                    <Button type="default" onClick={prevStep} style={{ marginRight: '8px', marginTop: '16px' }}>
                                                        {translations.cart_summary_back}
                                                    </Button>
                                                    <Button type="primary" onClick={nextStep}>
                                                        {translations.cart_summary_next}
                                                    </Button>
                                                </>
                                            )}
                                        </Row>
                                    </Space>
                                </Radio.Group>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
                        <Col xs={24}>
                            <Card title={translations.cart_summary_shopping_cart} style={{ height: '100%' }} id="form-step-4">
                                {cart.data.length === 0 ? (
                                    <div style={{ textAlign: 'center', padding: '20px' }}>
                                        <p>{translations.cart_summary_empty_message}</p>
                                        <Button
                                            type="primary"
                                            size="large"
                                            icon={<ShoppingOutlined />}
                                            onClick={() => navigate('/')}
                                        >
                                            {translations.cart_summary_continue_shopping}
                                        </Button>
                                    </div>
                                ) : (
                                    <>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={cart.data}
                                            renderItem={(item, index) => (
                                                <List.Item
                                                    actions={[
                                                        <Button onClick={() => handleQuantityChange(item, quantities[index] - 1)} icon={<MinusOutlined />} disabled={quantities[index] <= 1} size="small" />
                                                        ,
                                                        <Input
                                                            type="number"
                                                            min="1"
                                                            max={item.stock_quantity}
                                                            value={item.quantity}
                                                            readOnly
                                                            style={{ width: '60px' }}
                                                        />
                                                        ,
                                                        <Button onClick={() => handleQuantityChange(item, quantities[index] + 1)} icon={<PlusOutlined />} disabled={quantities[index] >= item.stock_quantity} size="small" />
                                                        ,
                                                        <Button
                                                            type="link"
                                                            danger
                                                            onClick={() => handleRemoveItem(item)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        title={
                                                            <Link to={`${item.full_url_path}`}>
                                                                {item.product_name}
                                                            </Link>
                                                        }
                                                        description={`${translations.cart_summary_unit_price}: ${calculatePrice(item.product_price, currency)}`}
                                                    />
                                                    <div>{calculatePrice(item.product_price * quantities[index], currency)}</div>
                                                </List.Item>
                                            )}
                                        />
                                        <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                            <div>
                                                {translations.cart_summary_subtotal}: {calculatePrice(cart.data.reduce((total, item, index) => total + item.product_price * quantities[index], 0), currency)}
                                            </div>
                                            {shippingMethod && carriersData && (
                                                <div>
                                                    {translations.cart_summary_shipping_cost}: {calculatePrice(calculateShippingCost(carriersData.getCarrierList.find(carrier => carrier.id === shippingMethod)?.rules), currency)}
                                                </div>
                                            )}
                                            {discount && (
                                                <div>
                                                    {translations.cart_summary_discount}: - {calculatePrice(discount, currency)}
                                                </div>
                                            )}
                                            <div>
                                                {translations.cart_summary_total}: {calculatePrice(calculateTotal(), currency)}
                                            </div>
                                        </div>
                                        <Form style={{ marginTop: '16px' }}>
                                            <Form.Item label={translations.cart_summary_discount_code}>
                                                <Space.Compact>
                                                    <Input
                                                        style={{ width: 'calc(60% - 8px)' }}
                                                        value={couponCode}
                                                        onChange={(e) => setCouponCode(e.target.value)}
                                                        placeholder={translations.cart_summary_discount_code_dummy}
                                                    />
                                                    <Button type="primary" onClick={handleApplyDiscount} loading={loadingDiscount}>
                                                        {translations.cart_summary_discount_code_button}
                                                    </Button>
                                                </Space.Compact>
                                            </Form.Item>
                                        </Form>
                                        <Space direction="vertical">
                                            <Checkbox
                                                checked={checkedTerms.terms}
                                                onChange={() => handleCheckboxChange('terms')}
                                                disabled={currentStep !== 4}
                                            >
                                                {translations.cart_summary_agree_terms_and_cond}
                                            </Checkbox>
                                            <Checkbox
                                                checked={checkedTerms.privacy}
                                                onChange={() => handleCheckboxChange('privacy')}
                                                disabled={currentStep !== 4}
                                            >
                                                {translations.cart_summary_agree_privacy_policy}
                                            </Checkbox>
                                        </Space>
                                        {currentStep === 4 && (
                                            <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
                                                <Col xs={24} md={12} span={12}>
                                                    <Button
                                                        type="default"
                                                        size="large"
                                                        block
                                                        icon={<ShoppingOutlined />}
                                                        onClick={() => navigate('/')}
                                                    >
                                                        {translations.cart_summary_continue_shopping}
                                                    </Button>
                                                </Col>
                                                <Col xs={24} md={12} span={12}>
                                                    <Button
                                                        type="primary"
                                                        size="large"
                                                        block
                                                        disabled={!checkedTerms.terms || !checkedTerms.privacy}
                                                        icon={<CheckOutlined />}
                                                        onClick={handleConfirmPurchase}
                                                    >
                                                        {translations.cart_summary_confirm_purchase}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Content>
    );
};

export default Cart;