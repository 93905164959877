import React, { createContext, useContext, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { publicClient, createAuthenticatedClient } from '../apollo/client';
import { useAuth } from './AuthContext';

const ClientsContext = createContext();

export const ApolloClientProvider = ({ children }) => {
  const { authData, setAuthData } = useAuth();

  const authenticatedClient = useMemo(() => {
    if (authData.is_authenticated && authData.session_token) {
      return createAuthenticatedClient(authData.session_token, { authData, setAuthData });
    }
    return null;
  }, [authData]);

  return (
    <ClientsContext.Provider value={{ publicClient, authenticatedClient }}>
      <ApolloProvider client={publicClient}>
        {children}
      </ApolloProvider>
    </ClientsContext.Provider>
  );
};

export const useClients = () => useContext(ClientsContext);