import { useEffect, useState, useContext } from 'react';
import { useSettings } from '../context/SettingsContext';

const useTranslation = (namespace = 'common') => {
    const { language } = useSettings();
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        const fetchTranslations = async () => {
        try {
            const loadedTranslations = await import(`../translations/${language.iso_code.toLowerCase()}/${namespace}.json`);
            setTranslations(loadedTranslations);
        } catch (error) {
            console.error('Error loading translations:', error);
        }
        };
        fetchTranslations();
    }, [language]);

    return translations;
};

export default useTranslation;
