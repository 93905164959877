import React from 'react';
import useTranslation from '../hooks/useTranslation';
import { Layout, Typography, Menu } from 'antd';

const { Sider } = Layout;
const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
    const translations = useTranslation('privacy_policy');

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                width={300}
                breakpoint='md'
                collapsedWidth="0"
                style={{
                    background: '#fff',
                    position: 'sticky',
                    top: 0,
                    paddingTop: 30,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Menu mode="inline" defaultSelectedKeys={['introduction']} style={{ height: '100%', borderRight: 0 }}>
                    <Menu.Item key="introduction">
                        <a href="#introduction">{translations.pp_introduction}</a>
                    </Menu.Item>
                    <Menu.Item key="data-collected">
                        <a href="#data-collected">{translations.pp_data_collected}</a>
                    </Menu.Item>
                    <Menu.Item key="purpose-data-processing">
                        <a href="#purpose-data-processing">{translations.pp_purpose_data_processing}</a>
                    </Menu.Item>
                    <Menu.Item key="legal-basis">
                        <a href="#legal-basis">{translations.pp_legal_basis}</a>
                    </Menu.Item>
                    <Menu.Item key="sharing-data">
                        <a href="#sharing-data">{translations.pp_sharing_data}</a>
                    </Menu.Item>
                    <Menu.Item key="international-data-transfer">
                        <a href="#international-data-transfer">{translations.pp_international_data_transfer}</a>
                    </Menu.Item>
                    <Menu.Item key="data-security">
                        <a href="#data-security">{translations.pp_data_security}</a>
                    </Menu.Item>
                    <Menu.Item key="data-retention">
                        <a href="#data-retention">{translations.pp_data_retention}</a>
                    </Menu.Item>
                    <Menu.Item key="user-rights">
                        <a href="#user-rights">{translations.pp_user_rights}</a>
                    </Menu.Item>
                    <Menu.Item key="contact-privacy">
                        <a href="#contact-privacy">{translations.pp_contact_privacy}</a>
                    </Menu.Item>
                </Menu>
            </Sider>

            <Layout style={{ margin: 40 }}>
                <div style={{ minHeight: '100vh' }}>
                    <Typography>
                        <Title level={3} id="introduction">
                            1. {translations.pp_introduction}
                        </Title>
                        <Paragraph>{translations.pp_introduction_content}</Paragraph>

                        <Title level={3} id="data-collected">
                            2. {translations.pp_data_collected}
                        </Title>
                        <Paragraph>{translations.pp_data_collected_content}</Paragraph>

                        <Title level={3} id="purpose-data-processing">
                            3. {translations.pp_purpose_data_processing}
                        </Title>
                        <Paragraph>{translations.pp_purpose_data_processing_content}</Paragraph>

                        <Title level={3} id="legal-basis">
                            4. {translations.pp_legal_basis}
                        </Title>
                        <Paragraph>{translations.pp_legal_basis_content}</Paragraph>

                        <Title level={3} id="sharing-data">
                            5. {translations.pp_sharing_data}
                        </Title>
                        <Paragraph>{translations.pp_sharing_data_content}</Paragraph>

                        <Title level={3} id="international-data-transfer">
                            6. {translations.pp_international_data_transfer}
                        </Title>
                        <Paragraph>{translations.pp_international_data_transfer_content}</Paragraph>

                        <Title level={3} id="data-security">
                            7. {translations.pp_data_security}
                        </Title>
                        <Paragraph>{translations.pp_data_security_content}</Paragraph>

                        <Title level={3} id="data-retention">
                            8. {translations.pp_data_retention}
                        </Title>
                        <Paragraph>{translations.pp_data_retention_content}</Paragraph>

                        <Title level={3} id="user-rights">
                            9. {translations.pp_user_rights}
                        </Title>
                        <Paragraph>{translations.pp_user_rights_content}</Paragraph>

                        <Title level={3} id="contact-privacy">
                            10. {translations.pp_contact_privacy}
                        </Title>
                        <Paragraph>{translations.pp_contact_privacy_content}</Paragraph>
                    </Typography>
                </div>
            </Layout>
        </Layout>
    );
};

export default PrivacyPolicy;