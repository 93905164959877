import React from 'react';
import { AuthProvider } from './AuthContext';
import { CartProvider } from './CartContext';
import { SettingsProvider } from './SettingsContext';
import { CategoriesProvider } from './CategoriesContext';

export const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <CartProvider>
        <SettingsProvider>
          <CategoriesProvider>
            {children}
          </CategoriesProvider>
        </SettingsProvider>
      </CartProvider>
    </AuthProvider>
  );
};