import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { notification, Button, Input } from 'antd';
import { ShoppingCartOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

import { useSettings } from '../../context/SettingsContext';
import { useCart } from '../../context/CartContext';
import { useAuth } from '../../context/AuthContext';
import { INSERT_CART, INSERT_CART_PRODUCT } from '../../graphql';
import useTranslation from '../../hooks/useTranslation';
import './AddToCartButton.css';

const AddToCartButton = ({ product }) => {
    const { language, currency } = useSettings();
    const { cart, setCart } = useCart();
    const { authData } = useAuth();
    const translations = useTranslation('product');
    const navigate = useNavigate();
    const [insertCart] = useMutation(INSERT_CART);
    const [insertCartProduct] = useMutation(INSERT_CART_PRODUCT);
    const [quantity, setQuantity] = useState(1);
    const [pendingCartUpdate, setPendingCartUpdate] = useState(null);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        const handleCartUpdate = async () => {
            if (pendingCartUpdate) {
                setIsAdding(true);
                try {
                    let cartId = cart?.id;
                    if (!cartId) {
                        const { data } = await insertCart({
                            variables: {
                                input: {
                                    token_guest_id: authData.guest_id,
                                    currency_id: currency.id,
                                    lang_id: language.id,
                                },
                            },
                        });
                        cartId = data.insertCart.token_id;
                        setCart(prevCart => ({
                            ...prevCart,
                            id: cartId,
                        }));
                    }

                    const { data } = await insertCartProduct({
                        variables: {
                            input: {
                                token_cart_id: cartId,
                                product_id: pendingCartUpdate.product_id,
                                quantity: pendingCartUpdate.quantity,
                            },
                        },
                    });

                    if (data.insertCartProduct.success) {
                        setCart((prevCart) => {
                            const updatedCart = { ...prevCart };
                            const itemIndex = updatedCart.data.findIndex(
                                (item) => item.product_id === pendingCartUpdate.product_id
                            );

                            if (itemIndex !== -1) {
                                updatedCart.data[itemIndex].quantity += pendingCartUpdate.quantity;
                            } else {
                                updatedCart.data.push({
                                    product_id: pendingCartUpdate.product_id,
                                    quantity: pendingCartUpdate.quantity
                                });
                            }

                            return updatedCart;
                        });
                        setQuantity(1);
                        notification.success({
                            message: translations.cart_button_add_insert_notif,
                            description: translations.cart_button_add_insert_desc.replace('%s', pendingCartUpdate.quantity),
                        });
                    } else {
                        notification.error({
                            message: translations.cart_button_add_error_notif,
                            description: translations.cart_button_add_error_desc,
                        });
                    }
                } catch (err) {
                    notification.error({
                        message: translations.cart_button_add_error_notif,
                        description: translations.cart_button_add_error_desc,
                    });
                } finally {
                    setPendingCartUpdate(null);
                    setIsAdding(false);
                }
            }
        };

        handleCartUpdate();
    }, [pendingCartUpdate]);

    const handleAddToCart = () => {
        if (isAdding) return;

        if (product && quantity > 0) {
            const existingProduct = cart?.data.find(item => item.product_id === product.id);
            const existingQuantity = existingProduct ? existingProduct.quantity : 0;
            const totalQuantity = existingQuantity + quantity;

            if (totalQuantity > product.stock_quantity) {
                notification.warning({
                    message: translations.cart_button_add_out_of_stock_notif,
                    description: translations.cart_button_add_out_of_stock_desc,
                });
                return;
            }

            setPendingCartUpdate({
                product_id: product.id,
                quantity: quantity,
            });
        } else {
            notification.error({
                message: translations.cart_button_add_invalid_quantity_notif,
                description: translations.cart_button_add_invalid_quantity_desc,
            });
        }
    };

    const handleGoToCart = () => {
        navigate('/cart');
    };

    const handleDecreaseQuantity = () => {
        setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
    };

    const handleIncreaseQuantity = () => {
        setQuantity((prevQuantity) => Math.min(product.stock_quantity, prevQuantity + 1));
    };

    return (
        <div className="add-to-cart-container">
            <div className="quantity-controls">
                <Button onClick={handleDecreaseQuantity} icon={<MinusOutlined />} disabled={quantity <= 1} />
                <Input
                    type="number"
                    min={1}
                    max={product.stock_quantity}
                    value={quantity}
                    readOnly
                    style={{ width: '60px' }}
                />
                <Button onClick={handleIncreaseQuantity} icon={<PlusOutlined />} disabled={quantity >= product.stock_quantity} />
            </div>
            <div className="buttons-container">
                <Button onClick={handleGoToCart} shape="round" size={'large'} icon={<ShoppingCartOutlined />}>
                    {translations.cart_button_add_view_cart}
                </Button>
                <Button
                    type="primary"
                    onClick={handleAddToCart}
                    shape="round"
                    size={'large'}
                    icon={<PlusOutlined />}
                    disabled={isAdding}
                >
                    {translations.cart_button_add_label}
                </Button>
            </div>
        </div>
    );
};

export default AddToCartButton;